import React, { useCallback } from "react"
import './notFound.css'
import { history } from '../../..'

export default function NotFound() {

    const backToHome = useCallback(() => {
        history.push('/')
    }, [])


    return (
        <div className='not-found'>
            <h1>Oops!</h1>
            <h2>We've looked everywhere and could not find this.</h2>
            <p >
                Go <button className='not-found-button' onClick={backToHome}>back </button > to the home page.
            </p>
        </div>
    )
}
