import { makeAutoObservable, reaction, runInAction } from "mobx"
import agent from "../api/agent"
import { User, UserFormPlanSelection, UserOrganisation } from "../models/user"
import { store } from './store'

export default class UserStore {
    user: User | null = null
    gettingUser = false
    loadingCreate = false
    keepAlive: any

    constructor() {
        makeAutoObservable(this)

        reaction(
            () => this.user,
            user => {
                store.notificationStore.removeAllNotification()

                if (user) {
                    this.pingServers()
                } else {
                    this.stopKeepAliveTimer()
                }
                // const userStatus = user?.status

                // if (userStatus && userStatus === "unverified") {
                //     let notification: Notification = {
                //         id: uuid(),
                //         header: "WARNING",
                //         type: "yellow",
                //         text: "Please complete your profile.",
                //         link: "/profile",
                //     }
                //     store.notificationStore.addNotification(notification)

                //     notification = {
                //         id: uuid(),
                //         header: "Important",
                //         type: "red",
                //         text: "Please complete your profile.",
                //         link: "/profile",
                //     }
                //     store.notificationStore.addNotification(notification)

                //     notification = {
                //         id: uuid(),
                //         header: "success",
                //         type: "green",
                //         text: "Please complete your profile.",
                //         link: "/profile",
                //     }
                //     store.notificationStore.addNotification(notification)

                //     notification = {
                //         id: uuid(),
                //         header: "info",
                //         type: "blue",
                //         text: "Please complete your profile.",
                //         link: "/profile",
                //     }
                //     store.notificationStore.addNotification(notification)
                // }
                // if (userStatus && userStatus === "verified") {
                //     const notification: Notification = {
                //         id: uuid(),
                //         header: "WARNING",
                //         type: "yellow",
                //         text: "Please complete your organisation details.",
                //         link: "/organisation",
                //     }
                //     store.notificationStore.addNotification(notification)
                // }
            }
        )
    }

    get isLoggedIn() {
        return !!this.user
    }

    private startKeepAliveTimer() {
        const timeout = 600000 // 10 min
        this.keepAlive = setTimeout(this.pingServers, timeout)
    }

    private stopKeepAliveTimer() {
        clearTimeout(this.keepAlive)
    }

    pingServers = async () => {
        this.stopKeepAliveTimer()
        try {
            await agent.Alive.initManagement()
            await agent.Alive.initBucket()
        } catch (error) {
            console.log(error)
        }
        this.startKeepAliveTimer()
    }

    setGettingUser = (state: boolean) => {
        this.gettingUser = state
    }

    setLoadingCreate = (state: boolean) => {
        this.loadingCreate = state
    }

    logoutUser = () => {
        this.user = null
    }

    setUser = (user: User) => {
        this.user = user
    }

    setUserFullname = (fullName: string) => {
        if (this.user) {
            this.user.fullName = fullName
        }
    }

    setUserStatus = (status: string) => {
        if (this.user) {
            this.user.status = status
        }
    }

    getUser = async () => {
        try {
            this.setGettingUser(true)

            const response = await agent.Account.current()

            if (response.data) {

                const user: User = {
                    userId: response.data.userId,
                    userName: response.data.userName,
                    email: response.data.email,
                    emailVerified: response.data.emailVerified,
                    fullName: response.data.fullName,
                    picture: response.data.picture,
                    nickName: response.data.nickName,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    status: response.data?.appMetadata?.status,
                    appMetadata: response.data?.appMetadata,
                    userMetadata: response.data?.userMetadata
                }

                // Setting default values for locale for the 1st time login, in future this will be retrieve from the browser preferences
                if (user.userMetadata === null) {
                    user.userMetadata = { language: "en", datePattern: "dd/MM/yyyy", timePattern: "24h", timeZone: "Dublin" }
                }

                runInAction(() => {
                    this.setUser(user)
                })
            }
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            this.setGettingUser(false)
        }
    }

    update = async (user: User) => {
        var redirectToOrg = false
        try {
            user.fullName = `${user?.firstName} ${user?.lastName}`

            if (user?.status === 'unverified') {
                user.status = 'verified'
                redirectToOrg = true
            }

            if (user?.appMetadata?.organisations === undefined || user?.appMetadata?.organisations?.length === 0) {
                redirectToOrg = true
            }

            const response = await agent.Account.update(user)

            if (response.data) {
                runInAction(() => {
                    this.setUser(user)
                })
            }
        } catch (error) {
            console.log(error)
            throw error
        }
        return redirectToOrg
    }


    register = async (creds: UserFormPlanSelection) => {
        try {
            this.setLoadingCreate(true)
            await agent.Account.register(creds)
        } catch (error) {
            console.log(error)
            this.setLoadingCreate(false)
            throw error
        }
    }


    setImage = (image: string) => {
        if (this.user) {
            this.user.picture = image
        }
    }

    setDisplayName = (displayName: string) => {
        if (this.user) {
            this.user.firstName = displayName
        }
    }

    setOrganisation = async (id: string, name: string) => {
        try {
            if (this.user) {
                const organisation: UserOrganisation = {
                    id: id,
                    name: name
                }

                runInAction(() => {

                    const orgList = this.user!.appMetadata?.organisations
                    if (orgList) {
                        orgList.push(organisation)
                        this.user!.appMetadata!.organisations = orgList
                    } else {
                        const newOrgList: UserOrganisation[] = []
                        newOrgList.push(organisation)
                        this.user!.appMetadata!.organisations = newOrgList
                    }
                    this.update(this.user!)
                })
            }
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    resendEmailConfirm = async () => {
        try {
            await agent.Account.resendEmailConfirm()
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}


