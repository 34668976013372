import DataGrid, {
    Column,
    Editing,
    Popup,
    Paging,
    Form as FormGrid,
    Texts,
} from 'devextreme-react/data-grid'
import React, { useEffect, useState } from 'react'
import Form, { Item, GroupItem, Label, TabbedItem, Tab, TabPanelOptions } from 'devextreme-react/form'
import { Address, AddressStatus, AddressTypes, GatesTypes } from '../../../app/models/address'
import { countryOptions } from '../../../app/common/options/countryOptions'
import { Button, LoadPanel } from 'devextreme-react'
import notify from 'devextreme/ui/notify'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from '../../../app/stores/store'
import { Tag, TagCategory, TagType } from '../../../app/models/tag'
import { observer } from 'mobx-react-lite'
import CustomTagBox from '../../../app/layout/customTagBox/CustomTagBox'

function CreateAddressForm() {
    const history = useHistory()

    const {
        organisationStore, userStore,
        addressStore: { loading, createAddress, updateAddress, getAddress },
        customerStore
    } = useStore()

    const { id } = useParams<{ id: string }>()

    const [address, setAddress] = useState<Address>()

    useEffect(() => {
        customerStore.getCustomers()
    }, [customerStore])

    useEffect(() => {
        if (userStore.user?.appMetadata?.organisations && userStore.user?.appMetadata?.organisations.length > 0) {
            const firstOrgId = userStore.user?.appMetadata?.organisations[0]?.id

            organisationStore.getOrganisation(firstOrgId).then(() => {

            })
        }
    }, [userStore.user, organisationStore])

    useEffect(() => {
        if (id) {
            getAddress(id).then(address => {
                if (address) {
                    setAddress(address)
                } else {
                    setAddress({
                        organisationId: organisationStore.organisation?.id,
                        code: '',
                        city: '',
                        countryISOCode: organisationStore.organisation?.address?.countryISOCode ?? "IE",
                        region: '',
                        name: '',
                        postcode: '',
                        address1: '',
                        address2: '',
                        flagGatesAndAreas: false,
                        type: 0,
                        status: 1
                    })
                }
            })
        } else {
            setAddress({
                organisationId: organisationStore.organisation?.id,
                code: '',
                city: '',
                countryISOCode: organisationStore.organisation?.address?.countryISOCode ?? "IE",
                region: '',
                name: '',
                postcode: '',
                address1: '',
                address2: '',
                flagGatesAndAreas: false,
                type: 0,
                status: 1
            })
        }
    }, [id, getAddress, organisationStore.organisation])




    if (loading || organisationStore.loadingOrganisation || customerStore.loading || address === undefined) {
        return <LoadPanel visible={true} />
    }


    function handleFormSubmit() {
        if (!address?.id) {
            createAddress(address!.organisationId!, address!).then(() => {
                notify('Address created', 'success')
                addNewTagsToOrganisation(address?.tags)
                history.goBack()
            }).catch((e) => {
                notify(`Address create error: ${e[0]}`, 'error')
            })
        }
        else {
            updateAddress(address!.organisationId!, address).then(() => {
                notify('Address updated', 'success')
                addNewTagsToOrganisation(address?.tags)
                history.goBack()
            }).catch((e) => {
                notify(`Address update error: ${e[0]}`, 'error')
            })
        }
    }

    function onTagsChanged(tags: Tag[]) {
        address!.tags = tags
    }

    function addNewTagsToOrganisation(tags?: Tag[]) {
        if (!tags) return
        // No new tags to be added
        if (tags.findIndex(tag => tag.isNew && tag.isNew === true) === -1) return

        tags.forEach(tag => {
            if (tag.isNew) {
                organisationStore.organisation?.tags?.push({ ...tag, isNew: false })
            }
        })
    }

    function handleFormClose() {
        history.goBack()
    }

    function handleSaveButton(e: any) {
        var validation = e?.validationGroup?.validate()
        if (validation?.isValid) {
            handleFormSubmit()
        }
    }


    const onEditorPreparing = (e: any) => {
        if (e.dataField === "name" && e.parentType === "dataRow") {
            e.editorOptions.disabled = !e.row.isNewRow
        }
    }


    const onTabChange = (e: any) => {

        if (e.itemData?.title === 'Address') {
            var elements = document.getElementsByName("addressInfoElement")
            elements[0]?.focus()
        }
    }

    const onInitialized = () => {
        var elements = document.getElementsByName("addressInfoElement")
        elements[0]?.focus()
    }


    return (
        <div className='content-block'>
            <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
                <div className={'form-avatar'} style={{ margin: '5px 0px 5px 5px' }}>
                </div>
                <h4 style={{ margin: '15px 5px 15px 30px' }}>Address</h4>
            </div>

            <div className={'content-block'}>
                <Form
                    id={'form-address'}
                    formData={address}
                    labelLocation={'top'}
                    showValidationSummary={true}
                    validationGroup={'addressFormValidation'}
                    onContentReady={onInitialized}
                >
                    <TabbedItem>
                        <TabPanelOptions deferRendering={false} tabIndex={0} onTitleClick={onTabChange} />
                        <Tab title="Address" icon="map" >
                            <GroupItem caption={'Address'} colCountByScreen={colCountByScreen} >
                                <Item
                                    dataField="personId"
                                    editorType='dxSelectBox'
                                    text={"Customer"}
                                    colSpan={2}
                                    isRequired={true}
                                    editorOptions={{
                                        inputAttr: { name: 'addressInfoElement' },
                                        items: customerStore?.customerList,
                                        searchEnabled: true,
                                        displayExpr: "name",
                                        valueExpr: "id",
                                        placeholder: "Select a customer..."
                                    }}>
                                    <Label text="Customer" />
                                </Item>
                                <Item
                                    dataField="type"
                                    editorType='dxSelectBox'
                                    text={"Type"}
                                    colSpan={2}
                                    isRequired={true}
                                    editorOptions={{
                                        items: AddressTypes,
                                        searchEnabled: true,
                                        displayExpr: "description",
                                        valueExpr: "value",
                                        placeholder: "Select a type..."
                                    }}>
                                    <Label text="Type" />
                                </Item>
                                <Item dataField="code" caption={"Code"} isRequired={true}>
                                    <Label text="Code" />
                                </Item>
                                <Item dataField="name" caption={"Name"} isRequired={true}>
                                    <Label text="Name" />
                                </Item>
                                <Item dataField="eori" caption={"EORI"} isRequired={true}>
                                    <Label text="EORI" />
                                </Item>
                                <Item dataField="status"
                                    editorType="dxSelectBox"
                                    text={"Status"}
                                    isRequired={true}
                                    editorOptions={{
                                        items: AddressStatus,
                                        searchEnabled: false,
                                        displayExpr: "description",
                                        valueExpr: "value",
                                        placeholder: "Status..."
                                    }}>
                                    <Label text="Status" />
                                </Item>
                                <Item dataField="address1" caption={"Address 1"} colSpan={2} isRequired={true}>
                                    <Label text="Address 1" />
                                </Item>
                                <Item dataField="address2" caption={"Address 2"} colSpan={2}>
                                    <Label text="Address 2" />
                                </Item>
                                <Item dataField="city" title={"City"}>
                                    <Label text="City" />
                                </Item>
                                <Item dataField="region" title={"Region"}>
                                    <Label text="County/Region" />
                                </Item>
                                <Item dataField="postcode" text={"Postcode"}>
                                    <Label text="Postcode" />
                                </Item>
                                <Item
                                    dataField="countryISOCode"
                                    editorType="dxSelectBox"
                                    text={"Country"}
                                    editorOptions={{
                                        items: countryOptions,
                                        searchEnabled: true,
                                        displayExpr: "name",
                                        valueExpr: "isoCode",
                                        placeholder: "Select a country..."
                                    }}>
                                    <Label text="Country" />
                                </Item>
                                <Item dataField="lat" title={"Latitude"}>
                                    <Label text="Latitude" />
                                </Item>
                                <Item dataField="lon" title={"Longitude"} colSpan={2} >
                                    <Label text="Longitude" />
                                </Item>
                                <Item dataField="notes" title={"Notes"} colSpan={2} >
                                    <Label text="Notes" />
                                </Item>
                                <Item caption={"Tags"} colSpan={2} >
                                    <Label text="Tags" />
                                    <CustomTagBox
                                        category={TagCategory.Address}
                                        type={TagType.User}
                                        tags={address?.tags ?? []}
                                        onTagsChanged={onTagsChanged}
                                        hideSelectedItems={true}
                                        acceptCustomValue={true}
                                    />
                                </Item>

                                <Item>
                                    <Button
                                        icon="check"
                                        type="success"
                                        stylingMode="contained"
                                        validationGroup='addressFormValidation'
                                        onClick={(e) => handleSaveButton(e)}
                                    />{' '}
                                    <Button
                                        icon="close"
                                        type="danger"
                                        stylingMode="contained"
                                        onClick={() => handleFormClose()}
                                    />
                                </Item>
                            </GroupItem>
                        </Tab>
                        <Tab title='Gates/Areas' icon="deleterow">
                            <DataGrid
                                className='stratum-grid'
                                dataSource={address?.areas ?? []}
                                keyExpr="name"
                                showBorders={true}
                                onEditorPreparing={onEditorPreparing}
                            >
                                <Paging enabled={false} />
                                <Editing
                                    refreshMode='reshape'
                                    mode="popup"
                                    allowUpdating={true}
                                    allowAdding={true}
                                    allowDeleting={true}
                                >
                                    <Texts
                                        saveRowChanges="ADD"
                                        cancelRowChanges="CANCEL"
                                    />
                                    <Popup title="Gates and areas" showTitle={true} width={700} height={600} />
                                    <FormGrid>
                                        <Item itemType="group" colCount={2} colSpan={2} >
                                            <Item dataField="type"
                                                editorType='dxSelectBox'
                                                text={"Type"}
                                                colSpan={2}
                                                isRequired={true}
                                                editorOptions={{
                                                    tabIndex: 0,
                                                    items: GatesTypes,
                                                    searchEnabled: false,
                                                    displayExpr: "description",
                                                    valueExpr: "value",
                                                    placeholder: "Select a type..."
                                                }}>
                                                <Label text="Type" />
                                            </Item>
                                            <Item dataField="name" colSpan={2} isRequired={true}>
                                                <Label text="Location" />
                                            </Item>
                                            <Item dataField="notes" colSpan={2} />
                                            <Item dataField="lat" />
                                            <Item dataField="lon" />
                                        </Item>
                                    </FormGrid>
                                </Editing>
                                <Column dataField="name" />
                                <Column dataField="type" lookup={{
                                    dataSource: GatesTypes,
                                    valueExpr: "value",
                                    displayExpr: "description"
                                }} >
                                </Column>
                                <Column dataField="notes" />
                                <Column dataField="lat" visible={false} />
                                <Column dataField="lon" visible={false} />
                            </DataGrid>
                            <div className='grid-lower-buttons'>
                                <Button
                                    icon="check"
                                    type="success"
                                    stylingMode="contained"
                                    validationGroup='addressFormValidation'
                                    onClick={(e) => handleSaveButton(e)}
                                />{' '}
                                <Button
                                    icon="close"
                                    type="danger"
                                    stylingMode="contained"
                                    onClick={() => handleFormClose()}
                                />
                            </div>
                        </Tab>
                    </TabbedItem>
                </Form>
            </div>
        </div>
    )
};

const colCountByScreen = {
    xs: 1,
    sm: 2,
    md: 2,
    lg: 2
}

export default observer(CreateAddressForm)