import { Tag } from "./tag"
import { IClearence } from "./clearences/clearences"
export const BucketStatus = [
    { value: -1, description: 'Inactive' },
    { value: 1, description: 'Active' },
]
export interface TransactionId {
    prefix: string,
    value: number
}

export interface OrganisationId {
    id: string,
    name: string
}

export interface IBucket {
    id?: string,
    accountId?: string,
    organisation?: OrganisationId,
    organisationId?: string,
    transactionId?: TransactionId,
    createdBy?: string,
    created?: string,
    requestedBy?: string,
    requested?: string,
    lastModifiedBy?: string,
    lastModified?: string,
    customerId?: string,
    customerName?: string,
    billTo?: string,
    billToName?: string,
    status?: number,
    tags?: Tag[],
    teams?: string[],
    clearances?: IClearence[]
}

export class Bucket implements IBucket {    
    constructor(init: BucketFormValues) {
        Object.assign(this, init)
    }
}

export interface BucketResponse extends IBucket {
    organisation: { id: string, name: string },
    customer: { id: string, name: string, type: number },
}

export class BucketFormValues implements IBucket {
    id?: string = undefined
    organisation?: OrganisationId = undefined
    organisationId?: string | undefined
    transactionId?: TransactionId = undefined
    createdBy?: string = undefined
    created?: string = undefined
    requestedBy?: string = undefined
    requested?: string = undefined
    lastModifiedBy?: string = undefined
    lastModified?: string = undefined
    customerId?: string = undefined
    customerName?: string = undefined
    billTo?: string = undefined
    billToName?: string = undefined
    status?: number = 1
    tags?: Tag[] = []
    teams?: string[] = []
    clearances?: IClearence[] = []

    constructor(bucket?: IBucket | BucketFormValues) {
        if (bucket) {
            this.id = bucket.id
            this.organisation = bucket.organisation
            this.organisationId = bucket.organisation?.id
            this.transactionId = bucket.transactionId
            this.createdBy = bucket.createdBy
            this.created = bucket.created
            this.requestedBy = bucket.requestedBy
            this.requested = bucket.requested
            this.lastModifiedBy = bucket.lastModifiedBy
            this.lastModified = bucket.lastModified
            this.customerId = bucket.customerId
            this.customerName = bucket.customerName
            this.billTo = bucket.billTo
            this.billToName = bucket.billToName
            this.status = bucket.status
            this.tags = bucket.tags
            this.teams = bucket.teams
            this.clearances = bucket.clearances
        }
    }
}