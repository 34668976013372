export const countryOptions = [
    { name: "Republic of Ireland", isoCode: "IE" },
    { name: "Northern Ireland", isoCode: "NI" },
    { name: "Great Britain", isoCode: "GB" },
    { name: "Afghanisation", isoCode: "AF" },
    { name: "Albania", isoCode: "AL" },
    { name: "Algeria", isoCode: "DZ" },
    { name: "American Samoa", isoCode: "AS" },
    { name: "Andorra", isoCode: "AD" },
    { name: "Angola", isoCode: "AO" },
    { name: "Anguilla", isoCode: "AI" },
    { name: "Antartica", isoCode: "AQ" },
    { name: "Antigua and Barbuda", isoCode: "AG" },
    { name: "Argentina", isoCode: "AR" },
    { name: "Armenia", isoCode: "AM" },
    { name: "Aruba", isoCode: "AW" },
    { name: "Austrailia", isoCode: "AU" },
    { name: "Austria", isoCode: "AT" },
    { name: "Azerbaijian", isoCode: "AZ" },
    { name: "Bahamas (the)", isoCode: "BS" },
    { name: "Bahrain", isoCode: "BH" },
    { name: "Bangladesh", isoCode: "BD" },
    { name: "Barbados", isoCode: "BB" },
    { name: "Belarus", isoCode: "BY" },
    { name: "Belgium", isoCode: "BE" },
    { name: "Belize", isoCode: "BZ" },
    { name: "Benin", isoCode: "BJ" },
    { name: "Bermuda", isoCode: "BM" },
    { name: "Aland Islands", isoCode: "AX" },
    { name: "Bhutan", isoCode: "BT" },
    { name: "Bolivia", isoCode: "BO" },
    { name: "Bonaire, Sint Eustatius and Saba", isoCode: "BQ" },
    { name: "Bosnia and Herzegovina", isoCode: "BA" },
    { name: "Botswana", isoCode: "BW" },
    { name: "Bouvet Island", isoCode: "BV" },
    { name: "Brazil", isoCode: "BR" },
    { name: "British Indian Ocean Territory (the)", isoCode: "IO" },
    { name: "Brunei Darussalam", isoCode: "BN" },
    { name: "Bulgaria", isoCode: "BG" },
    { name: "Burkina Faso", isoCode: "BF" },
    { name: "Burundi", isoCode: "BI" },
    { name: "Cabo Verde", isoCode: "CV" },
    { name: "Cambodia", isoCode: "KH" },
    { name: "Cameroon", isoCode: "CM" },
    { name: "Canada", isoCode: "CA" },
    { name: "Cayman Islands (the)", isoCode: "KY" },
    { name: "Central African Republic (the)", isoCode: "CF" },
    { name: "Chad", isoCode: "TD" },
    { name: "Chile", isoCode: "CL" },
    { name: "China", isoCode: "CN" },
    { name: "Christmas Island", isoCode: "CX" },
    { name: "Cocos (Keeling) Islands (the)", isoCode: "CC" },
    { name: "Colombia", isoCode: "CO" },
    { name: "Comoros (the)", isoCode: "KM" },
    { name: "Congo (the Democratic Replublic of the)", isoCode: "CD" },
    { name: "Congo (the)", isoCode: "CG" },
    { name: "Cook Islands (the)", isoCode: "CK" },
    { name: "Costa Rica", isoCode: "CR" },
    { name: "Croatia", isoCode: "HR" },
    { name: "Cuba", isoCode: "CU" },
    { name: "Curacao", isoCode: "CW" },
    { name: "Cyprus", isoCode: "CY" },
    { name: "Czechia", isoCode: "CZ" },
    { name: "Cote d'Ivoire", isoCode: "CI" },
    { name: "Denmark", isoCode: "DK" },
    { name: "Djibouti", isoCode: "DJ" },
    { name: "Dominica", isoCode: "DM" },
    { name: "Dominican Republic (the)", isoCode: "DO" },
    { name: "Ecuador", isoCode: "EC" },
    { name: "Egypt", isoCode: "EG" },
    { name: "El Salvador", isoCode: "SV" },
    { name: "Equatorial Guinea", isoCode: "GQ" },
    { name: "Eritrea", isoCode: "ER" },
    { name: "Estonia", isoCode: "EE" },
    { name: "Eswatini", isoCode: "SZ" },
    { name: "Ethiopia", isoCode: "ET" },
    { name: "Falkland Islands (the)", isoCode: "FK" },
    { name: "Faroe Islands (the)", isoCode: "FO" },
    { name: "Fiji", isoCode: "FJ" },
    { name: "Finland", isoCode: "FI" },
    { name: "France", isoCode: "FR" },
    { name: "French Guiana", isoCode: "GF" },
    { name: "French Polynesia", isoCode: "PF" },
    { name: "French Southern Territories (the)", isoCode: "TF" },
    { name: "Gabon", isoCode: "GA" },
    { name: "Gambia (the)", isoCode: "GM" },
    { name: "Georgia", isoCode: "GE" },
    { name: "Germany", isoCode: "DE" },
    { name: "Ghana", isoCode: "GH" },
    { name: "Gibraltar", isoCode: "GI" },
    { name: "Greece", isoCode: "GR" },
    { name: "Greenland", isoCode: "GL" },
    { name: "Grenada", isoCode: "GD" },
    { name: "Guadeloupe", isoCode: "GP" },
    { name: "Guam", isoCode: "GU" },
    { name: "Guatemala", isoCode: "GT" },
    { name: "Guernsey", isoCode: "GG" },
    { name: "Guinea", isoCode: "GN" },
    { name: "Guinea-Bissau", isoCode: "GW" },
    { name: "Guyana", isoCode: "GY" },
    { name: "Haiti", isoCode: "HT" },
    { name: "Heard Island and McDonald Islands", isoCode: "HM" },
    { name: "Holy See (the)", isoCode: "VA" },
    { name: "Honduras", isoCode: "HN" },
    { name: "Hong Kong", isoCode: "HK" },
    { name: "Hungary", isoCode: "HU" },
    { name: "Iceland", isoCode: "IS" },
    { name: "India", isoCode: "IN" },
    { name: "Indonesia", isoCode: "ID" },
    { name: "Iran (Islamic Republic of)", isoCode: "IR" },
    { name: "Iraq", isoCode: "IQ" },
    { name: "Isle of Man", isoCode: "IM" },
    { name: "Israel", isoCode: "IL" },
    { name: "Italy", isoCode: "IT" },
    { name: "Jamaica", isoCode: "JM" },
    { name: "Japan", isoCode: "JP" },
    { name: "Jersey", isoCode: "JE" },
    { name: "Jordan", isoCode: "JO" },
    { name: "Kazakhstan", isoCode: "KZ" },
    { name: "Kenya", isoCode: "KE" },
    { name: "Kiribati", isoCode: "KI" },
    { name: "Korea (the Democratic People's Republic of)", isoCode: "KP" },
    { name: "Korea (the Republic of)", isoCode: "KR" },
    { name: "Kuwait", isoCode: "KW" },
    { name: "Kyrgyzstan", isoCode: "KG" },
    { name: "Lao People's Democratic Republic (the)", isoCode: "LA" },
    { name: "Latvia", isoCode: "LV" },
    { name: "Lebanon", isoCode: "LB" },
    { name: "Lesotho", isoCode: "LS" },
    { name: "Liberia", isoCode: "LR" },
    { name: "Libya", isoCode: "LY" },
    { name: "Liechtenstein", isoCode: "LI" },
    { name: "Lithuania", isoCode: "LT" },
    { name: "Luxembourg", isoCode: "LU" },
    { name: "Macao", isoCode: "MO" },
    { name: "Madagascar", isoCode: "MG" },
    { name: "Malawi", isoCode: "MW" },
    { name: "Malaysia", isoCode: "MY" },
    { name: "Maldives", isoCode: "MV" },
    { name: "Mali", isoCode: "ML" },
    { name: "Malta", isoCode: "MT" },
    { name: "Marshall Islands (the)", isoCode: "MH" },
    { name: "Martinique", isoCode: "MQ" },
    { name: "Mauritania", isoCode: "MR" },
    { name: "Mauritius", isoCode: "MU" },
    { name: "Mayotte", isoCode: "YT" },
    { name: "Mexico", isoCode: "MX" },
    { name: "Micronesia (Federated States of)", isoCode: "FM" },
    { name: "Moldova (the Republic of)", isoCode: "MD" },
    { name: "Monaco", isoCode: "MC" },
    { name: "Mongolia", isoCode: "MN" },
    { name: "Montenegro", isoCode: "ME" },
    { name: "Montserrat", isoCode: "MS" },
    { name: "Morocco", isoCode: "MA" },
    { name: "Mozambique", isoCode: "MZ" },
    { name: "Myanmar", isoCode: "MM" },
    { name: "Namibia", isoCode: "NA" },
    { name: "Nauru", isoCode: "NR" },
    { name: "Nepal", isoCode: "NP" },
    { name: "Netherlands (the)", isoCode: "NL" },
    { name: "New Caledonia", isoCode: "NC" },
    { name: "New Zealand", isoCode: "NZ" },
    { name: "Nicaragua", isoCode: "NI" },
    { name: "Niger (the)", isoCode: "NE" },
    { name: "Nigeria", isoCode: "NG" },
    { name: "Niue", isoCode: "NU" },
    { name: "Norfolk Island", isoCode: "NF" },
    { name: "North Macedonia", isoCode: "MK" },
    { name: "Northern Mariana Islands (the)", isoCode: "MP" },
    { name: "Norway", isoCode: "NO" },
    { name: "Oman", isoCode: "OM" },
    { name: "Pakistan", isoCode: "PK" },
    { name: "Palau", isoCode: "PW" },
    { name: "Palestine, State of", isoCode: "PS" },
    { name: "Panama", isoCode: "PA" },
    { name: "Papua New Guinea", isoCode: "PG" },
    { name: "Paraguay", isoCode: "PY" },
    { name: "Peru", isoCode: "PE" },
    { name: "Philippines (the)", isoCode: "PH" },
    { name: "Pitcairn", isoCode: "PN" },
    { name: "Poland", isoCode: "PL" },
    { name: "Portugal", isoCode: "PT" },
    { name: "Puerto Rico", isoCode: "PR" },
    { name: "Qatar", isoCode: "QA" },
    { name: "Romania", isoCode: "RO" },
    { name: "Russian Federation (the)", isoCode: "RU" },
    { name: "Rwanda", isoCode: "RW" },
    { name: "Reunion", isoCode: "RE" },
    { name: "Saint Barthelemy", isoCode: "BL" },
    { name: "Saint Helena, Ascension and Tristan da Cunha", isoCode: "SH" },
    { name: "Saint Kitts and Nevis", isoCode: "KN" },
    { name: "Saint Lucia", isoCode: "LC" },
    { name: "Saint Martin (French part) ", isoCode: "MF" },
    { name: "Saint Pierre and Miquelon", isoCode: "PM" },
    { name: "Saint Vincent and the Grenadines", isoCode: "VC" },
    { name: "Samoa", isoCode: "WS" },
    { name: "San Marino", isoCode: "SM" },
    { name: "Sao Tome and Principe", isoCode: "ST" },
    { name: "Saudi Arabia", isoCode: "SA" },
    { name: "Senegal", isoCode: "SN" },
    { name: "Serbia", isoCode: "RS" },
    { name: "Seychelles", isoCode: "SC" },
    { name: "Sierra Leone", isoCode: "SL" },
    { name: "Singapore", isoCode: "SG" },
    { name: "Sint Maarten (Dutch part)", isoCode: "SX" },
    { name: "Slovakia", isoCode: "SK" },
    { name: "Slovenia", isoCode: "SI" },
    { name: "Solomon Islands", isoCode: "SB" },
    { name: "Somalia", isoCode: "SO" },
    { name: "South Africa", isoCode: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", isoCode: "GS" },
    { name: "South Sudan", isoCode: "SS" },
    { name: "Spain", isoCode: "ES" },
    { name: "Sri Lanka", isoCode: "LK" },
    { name: "Sudan (the)", isoCode: "SD" },
    { name: "Suriname", isoCode: "SR" },
    { name: "Svalbard and Jan Mayen", isoCode: "SJ" },
    { name: "Sweden", isoCode: "SE" },
    { name: "Switzerland", isoCode: "CH" },
    { name: "Syrian Arab Republic (the)", isoCode: "SY" },
    { name: "Taiwan (Province of China)", isoCode: "TW" },
    { name: "Tajikistan", isoCode: "TJ" },
    { name: "Tanzania, the United Republic of", isoCode: "TZ" },
    { name: "Thailand", isoCode: "TH" },
    { name: "Timor-Leste", isoCode: "TL" },
    { name: "Togo", isoCode: "TG" },
    { name: "Tokelau", isoCode: "TK" },
    { name: "Tonga", isoCode: "TO" },
    { name: "Trinidad and Tobago", isoCode: "TT" },
    { name: "Tunisia", isoCode: "TN" },
    { name: "Turkey", isoCode: "TR" },
    { name: "Turkmenistan", isoCode: "TM" },
    { name: "Turks and Caicos Islands (the)", isoCode: "TC" },
    { name: "Tuvalu", isoCode: "TV" },
    { name: "Uganda", isoCode: "UG" },
    { name: "Ukraine", isoCode: "UA" },
    { name: "United Arab Emirates (the)", isoCode: "AE" },
    { name: "United States Minor Outlying Islands (the)", isoCode: "UM" },
    { name: "United States of America", isoCode: "US" },
    { name: "Uruguay", isoCode: "UY" },
    { name: "Uzbekistan", isoCode: "UZ" },
    { name: "Vanuatu", isoCode: "VU" },
    { name: "Venezuela (Bolivarian Republic of)", isoCode: "VE" },
    { name: "Viet Nam", isoCode: "VN" },
    { name: "Virgin Islands (British)", isoCode: "VG" },
    { name: "Virgin Islands (U.S.)", isoCode: "VI" },
    { name: "Wallis and Futuna", isoCode: "WF" },
    { name: "Western Sahara", isoCode: "EH" },
    { name: "Yemen", isoCode: "YE" },
    { name: "Zambia", isoCode: "ZM" },
    { name: "Zimbabwe", isoCode: "ZW" },
]