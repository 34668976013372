import React from 'react'
import { observer } from "mobx-react-lite"
import './registrationThankYou.css'
import { Button } from 'devextreme-react'
import { useHistory } from 'react-router-dom'

export default observer(function RegistrationThankYou() {
    const history = useHistory()

    function handleBachToHomepage() {
        history.push('/')
    }

    return (
        <div className='centered'>
            <h3 >We are happy that you have signed up for<br /><a href={window.location.origin} color="inherit" target="_blank" rel="noopener noreferrer" >
                mycustomclouds.com</a>{' '}
            </h3>
            <h4 >
                To start using the system, please verify your account by confirming your email address.
            </h4>
            <Button icon='home' type='default' text='Back To Home Page' onClick={() => handleBachToHomepage()} />
        </div>
    )
})
