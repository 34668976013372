import React from 'react'
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Form,
  Lookup,
} from 'devextreme-react/data-grid'
import { Item, Label } from 'devextreme-react/form'
import { useStore } from '../../../app/stores/store'
import notify from 'devextreme/ui/notify'
import { MetadataStatus } from '../../../app/models/metadata'
import { NominalCodeTypes } from '../../../app/models/nominalCode'
import { v4 as uuid } from "uuid"
import { observer } from 'mobx-react-lite'

const NominalCodesGrid = () => {
  const { organisationStore } = useStore()
  const { organisation, deleteNominal, updateNominal, createNominal, removeNominalFromList } = organisationStore

  const handleRowRemoved = (row: any) => {
    deleteNominal(organisation!.id!, row.data.id).then(() => {
      notify(`Nominal deleted: ${row.data.code} - ${row.data.description}`, 'success')
    })
  }

  const handleRowInserting = (row: any) => {
    const isCanceled = new Promise((resolve, reject) => {
      createNominal(organisation!.id!, row.data).then(() => {
        return resolve(false)
      }).catch((error) => {
        reject(error)
      })
    })
    row.cancel = isCanceled
  }

  const handleRowInserted = (row: any) => {
    notify(`Nominal Created: ${row.data.code} - ${row.data.description}`, 'success')
    // remove the temp nominal created before the DB save....
    removeNominalFromList(row.data.id)
  }

  const handleRowUpdated = (row: any) => {
    updateNominal(organisation!.id!, row.data).then(() => {
      notify(`Nominal Updated: ${row.data.code} - ${row.data.description}`, 'success')
    })
  }

  const handleInitNewRow = (e: any) => {
    const defaultValues = {
      id: uuid(),
      countryCode: null,
      displayOrder: 0,
      isDeleted: false,
      symbol: null,
      default: false,
      code: '',
      description: '',
      value: '',
      type: NominalCodeTypes[0].description,
      status: 1,
    }
    e.data = defaultValues
  }


  return (
    <DataGrid
      className='stratum-grid'
      dataSource={organisation?.listSettings?.nominalCodes ?? []}
      keyExpr="id"
      showBorders={true}
      onRowRemoved={handleRowRemoved}
      onRowInserting={handleRowInserting}
      onRowInserted={handleRowInserted}
      onRowUpdated={handleRowUpdated}
      onInitNewRow={handleInitNewRow}
    >
      <Paging enabled={false} />
      <Editing
        mode="popup"
        refreshMode='reshape'
        allowUpdating={true}
        allowAdding={true}
        allowDeleting={true}>
        <Popup title="Nominal code" showTitle={true} width={700} height={525} />
        <Form>
          <Item itemType="group" colCount={2} colSpan={2}>
            <Item dataField="code" isRequired={true} />
            <Item dataField="description" isRequired={true} />
            <Item dataField="value" isRequired={true} />
            <Item dataField="type"
              isRequired={true}
              editorType="dxSelectBox"
              text={"Type"}
              editorOptions={{
                items: NominalCodeTypes,
                searchEnabled: true,
                displayExpr: "description",
                valueExpr: "description",
                placeholder: "Type..."
              }}>
              <Label text="Type" />
            </Item>

            <Item dataField="status"
              isRequired={true}
              editorType="dxSelectBox"
              text={"Status"}
              editorOptions={{
                items: MetadataStatus,
                searchEnabled: false,
                displayExpr: "description",
                valueExpr: "value",
                placeholder: "Status..."
              }}>
              <Label text="Status" />
            </Item>
          </Item>
        </Form>
      </Editing>
      <Column dataField="code" />
      <Column dataField="description" />
      <Column dataField="type" />
      <Column dataField="value" />
      <Column dataField="status">
        <Lookup
          dataSource={MetadataStatus}
          valueExpr="value"
          displayExpr="description"
        />
      </Column>
    </DataGrid>
  )
}

export default observer(NominalCodesGrid)