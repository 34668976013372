import agent from "../api/agent"
import { BucketResponse, IBucket, TransactionId } from '../models/bucket'
import { makeAutoObservable, runInAction } from "mobx"
import { IClearence } from "../models/clearences/clearences"
import { PortClearenceMrn } from '../models/clearences/portClearence'

export default class BucketStore {
    bucketList: IBucket[] = []
    bucket: IBucket | undefined = undefined
    clearance: IClearence | undefined = undefined
    exitMrns: PortClearenceMrn[] = []
    entryMrns: PortClearenceMrn[] = []
    loadingBucket: boolean = false

    constructor() {
        makeAutoObservable(this)
    }


    addMrn = (mrn: string, isEntry: Boolean) => {
        const newValue: PortClearenceMrn = {
            number: mrn
        }
        isEntry
            ? this.entryMrns.push(newValue)
            : this.exitMrns.push(newValue)
    }

    addMrnArray = (mrns: PortClearenceMrn[], isEntry: Boolean) => {

        isEntry
            ? this.entryMrns = mrns
            : this.exitMrns = mrns
    }

    removeMrn = (mrn: string, isEntry: Boolean) => {
        isEntry
            ? this.entryMrns = this.entryMrns.filter(obj => obj.number !== mrn)
            : this.exitMrns = this.exitMrns.filter(obj => obj.number !== mrn)
    }

    resetMrns = () => {
        this.entryMrns = []
        this.exitMrns = []
    }

    setLoadingBucket = (state: boolean) => {
        this.loadingBucket = state
    }

    setBucket = (bucket?: IBucket) => {
        this.bucket = bucket
    }

    setClearence = (clearance?: IClearence) => {
        this.clearance = clearance
    }

    getClearence = (clearenceTransactionId: TransactionId): IClearence | undefined => {
        if (!this.bucket) {
            console.error('bucketStore.getClearence', 'this.bucket is undefined')
            return
        }

        let clearence = this.bucket.clearances?.find(clearence =>
            clearence.transactionId?.prefix === clearenceTransactionId.prefix &&
            clearence.transactionId?.value === clearenceTransactionId.value)

        if (!clearence) {
            console.error('bucketStore.getClearence', `clearence not found for transactionId number ${clearenceTransactionId.value}`)
            return
        }

        return clearence
    }

    addClearence = (clearence: IClearence) => {
        if (!this.bucket) {
            console.error('bucketStore.addClearence', 'this.bucket is undefined')
            return
        }

        if (!this.bucket.clearances) {
            this.bucket.clearances = []
        }

        console.log('addClearence.before', this.bucket?.clearances)
        runInAction(() => {
            this.bucket?.clearances?.push(clearence)
        })
        console.log('addClearence.after', this.bucket?.clearances)
    }

    updateClearence = (clearence: IClearence) => {
        if (!this.bucket) {
            console.error('bucketStore.updateClearence', 'this.bucket is undefined')
            return
        }

        if (!clearence.id) {
            console.error('bucketStore.updateClearence', 'clearence.id is undefined')
            return
        }

        if (this.bucket.clearances)
            this.bucket.clearances = this.bucket?.clearances?.map(item => item.id === clearence.id ? clearence : item)

    }

    deleteClearence = (clearenceId: string) => {
        if (!this.bucket) {
            console.error('bucketStore.deleteClearence', 'this.bucket is undefined')
            return
        }

        if (!clearenceId) {
            console.error('bucketStore.deleteClearence', 'clearenceId is empty or undefined')
            return
        }

        let newClearences = this.bucket?.clearances?.filter(item => item.id !== clearenceId)

        if (this.bucket.clearances) {
            runInAction(() => {
                this.bucket!.clearances = newClearences
            })
        }
    }

    getBuckets = async () => {
        try {
            this.setLoadingBucket(true)
            const response = await agent.Bucket.getAll()
            console.log('Bucket data', response)

            if (response.data) {
                const tempArray: IBucket[] = []

                response.data.map((data: BucketResponse) => (
                    tempArray.push(this.createBucketObj(data))
                ))

                runInAction(() => {
                    this.bucketList = tempArray
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingBucket(false)
            })
        }
    }

    getBucket = async (id: string) => {
        try {
            this.setLoadingBucket(true)

            const response = await agent.Bucket.get(id)

            console.log('store.getBucket', response.data)
            if (response.data)
                return this.createBucketObj(response.data)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingBucket(false)
            })
        }
    }

    loadBucket = async (id: string) => {
        let bucket = this.getLocalBucket(id)
        if (bucket) {
            runInAction(() => {
                this.bucket = bucket
            })
            return bucket
        } else {
            this.setLoadingBucket(true)
            try {
                const response = await agent.Bucket.get(id)
                if (response.data) {
                    bucket = this.createBucketObj(response.data)
                    runInAction(() => {
                        this.bucket = bucket
                    })
                    return bucket
                }
            } catch (error) {
                console.log(error)
                this.setLoadingBucket(false)
            } finally {
                runInAction(() => {
                    this.setLoadingBucket(false)
                })
            }
        }
    }

    clearSelectedBucket = () => {
        this.bucket = undefined
    }

    private getLocalBucket = (id: string) => {
        const bucket = this.bucketList.find(x => x.id === id)
        return bucket
    }

    deleteBucket = async (organisationId: string, id: string) => {
        try {
            this.setLoadingBucket(true)

            await agent.Bucket.delete(organisationId, id)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingBucket(false)
            })
        }
    }

    updateBucket = async (organisationId: string, bucket: IBucket) => {
        try {
            this.setLoadingBucket(true)
            console.log("updateBucket", bucket)

            await agent.Bucket.update(organisationId, bucket)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingBucket(false)
            })
        }
    }

    createBucket = async (organisationId: string, bucket: IBucket) => {
        try {
            this.setLoadingBucket(true)
            console.log("createBucket", bucket)

            const response = await agent.Bucket.create(organisationId, bucket)

            if (response.data) {

                let bucketInfo = this.createBucketObj({
                    ...response.data,
                    organisation: bucket.organisation,
                    organisationId: bucket.organisationId,
                    customer: bucket.customerId
                })

                runInAction(() => {
                    this.bucketList?.push(bucketInfo)
                })

                console.log("createBucket.return", bucketInfo)

                return bucketInfo
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingBucket(false)
            })
        }
    }

    private createBucketObj(data: BucketResponse): IBucket {
        let bucket: IBucket = {
            id: data.id,
            organisation: data.organisation,
            organisationId: data.organisation?.id,
            transactionId: data.transactionId,
            requestedBy: data.requestedBy,
            requested: data.requested,
            customerId: data.customer?.id,
            customerName: data.customerName,
            billTo: data.billTo,
            billToName: data.billToName,
            status: data.status,
            clearances: data.clearances,
            created: data.created,
            createdBy: data.createdBy,
            lastModified: data.lastModified,
            lastModifiedBy: data.lastModifiedBy,
            tags: data.tags,
            teams: data.teams,
        }

        return bucket
    }
}