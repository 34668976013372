import { Customer, CustomerFormValues, CustomerResponse } from "./customer";

export const SupplierTypes = [
    { value: 1, description: 'Ferry operators' },
    { value: 2, description: 'Subcontractors' },
    { value: 3, description: 'Service providers' },
    { value: 4, description: 'Equipment providers' },
    { value: 5, description: 'Ferry Agents' },
    { value: 6, description: 'LoLo operators' },
    { value: 7, description: 'Contract Hire' },
    { value: 8, description: 'Deep sea' },
    { value: 9, description: 'Train operators' }, 
]

export const SupplierStatus = [
    { value: -1, description: 'Inactive' },
	{ value: 1, description: 'Active' },
]

export interface Supplier extends Customer {
    operatorType?: number
}

export interface SupplierResponse extends CustomerResponse {
    operatorType?: number
}

export class SupplierFormValues extends CustomerFormValues {
    operatorType?: number

    constructor(supplier?: SupplierFormValues | Supplier | undefined) {
        super(supplier)
        
        if (supplier)
            this.operatorType = supplier.operatorType
    }
}

