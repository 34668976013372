import { createContext, useContext } from "react"
import UserStore from "./userStore"
import CommonStore from "./commonStore"
import ModalStore from "./modalStore"
import OrganisationStore from './organisationStore'
import NotificationStore from './notificationStore'
import AddressStore from './addressStore'
import CustomerStore from './customerStore'
import SupplierStore from './supplierStore'
import BucketStore from './bucketStore'
import DeclarationClearenceStore from "./declarationClearenceStore"
import AccountStore from "./accountStore"
import PortClearenceStore from "./portClearenceStore"

interface Store {
    userStore: UserStore,
    commonStore: CommonStore,
    modalStore: ModalStore,
    organisationStore: OrganisationStore,
    notificationStore: NotificationStore,
    addressStore: AddressStore,
    customerStore: CustomerStore,
    supplierStore: SupplierStore,
    bucketStore: BucketStore,
    declarationClearenceStore: DeclarationClearenceStore,
    portClearenceStore: PortClearenceStore,
    accountStore: AccountStore
}

export const store: Store = {
    userStore: new UserStore(),
    commonStore: new CommonStore(),
    modalStore: new ModalStore(),
    organisationStore: new OrganisationStore(),
    notificationStore: new NotificationStore(),
    addressStore: new AddressStore(),
    customerStore: new CustomerStore(),
    supplierStore: new SupplierStore(),
    bucketStore: new BucketStore(),
    declarationClearenceStore: new DeclarationClearenceStore(),
    portClearenceStore: new PortClearenceStore(),
    accountStore: new AccountStore()
}

export const StoreContext = createContext(store)

export function useStore() {
    return useContext(StoreContext)
}