import ScrollView from 'devextreme-react/scroll-view';
import React from 'react';

const TermsOfServicePage = () => {
    return(
            <ScrollView  
                scrollByContent={true}                                          
                showScrollbar='always'
                scrollByThumb={true} >
                    <p><b>Terms of service</b></p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin varius at erat sed sodales. Maecenas consequat eleifend quam, tristique vestibulum mauris euismod sit amet. Phasellus ullamcorper mi id ex suscipit, a condimentum dui tempor. Donec fermentum sem diam, nec condimentum tellus bibendum vitae. Aliquam nec aliquam libero. Mauris eget enim mattis, convallis mi ultricies, vulputate dui. Curabitur finibus convallis nunc quis malesuada. Nunc libero libero, vehicula id sapien sit amet, condimentum dignissim est. Ut vitae sodales elit.</p>
                    <p>Maecenas sit amet diam a nisl fringilla aliquam eget in lacus. Etiam eu semper velit, sit amet bibendum eros. Nulla efficitur eros et accumsan efficitur. Vivamus dui lectus, malesuada vel gravida nec, lacinia id justo. Phasellus quis malesuada metus. Donec ac interdum felis. Suspendisse potenti. Nullam rhoncus egestas leo non blandit. Sed quis placerat elit. Mauris eget dolor et ex euismod placerat sit amet nec nunc. Sed porta blandit eros non blandit. Aliquam faucibus sit amet metus non efficitur. Pellentesque sagittis, enim quis mattis porttitor, eros orci maximus nibh, nec finibus risus elit nec sapien. Maecenas faucibus, dolor a euismod lacinia, nibh velit sollicitudin sapien, eu rutrum lorem nulla viverra lacus. Nunc feugiat mi dolor, sed lacinia turpis molestie sit amet. Aenean congue venenatis mauris, et ullamcorper dolor lobortis sed.</p>
                    <p>Suspendisse hendrerit nibh felis, vel rutrum magna maximus a. Phasellus mauris dui, maximus eget malesuada eget, feugiat id quam. Curabitur vitae faucibus lorem. Vestibulum venenatis neque sit amet lorem varius, ut faucibus velit sagittis. Quisque a ultricies elit. Donec nec eros laoreet mauris pretium rutrum non id magna. Pellentesque tortor nunc, consequat sit amet tortor vitae, vulputate dignissim tellus. Mauris eleifend risus ut neque dapibus aliquet. Pellentesque iaculis risus non lorem vestibulum dictum. Ut vel facilisis nulla. Nam ut tincidunt nunc, a iaculis enim. Mauris convallis elementum risus, sit amet molestie velit scelerisque in. Proin pretium, velit non facilisis cursus, ante purus vehicula quam, sit amet vulputate ipsum eros sodales velit. Suspendisse pharetra mi lectus. Phasellus feugiat augue eget aliquam posuere. Fusce feugiat porttitor tortor, ultrices molestie metus varius in.</p>
                    <p>Morbi consequat eget est vitae tempus. Fusce justo purus, gravida ut imperdiet sed, rhoncus eget elit. Sed consequat hendrerit ante, vel ultrices velit posuere quis. Maecenas sollicitudin massa elit, sed viverra nisi mollis nec. Nunc pellentesque augue vitae suscipit molestie. Phasellus sed massa tempor, vehicula mauris sed, egestas mauris. Pellentesque finibus dapibus lacus eu dictum. Duis mattis bibendum ex, ac ultrices enim hendrerit at. Pellentesque sit amet ligula ut augue aliquet porta. Quisque feugiat hendrerit vulputate. Quisque eget cursus nunc, in interdum ex. Donec vel turpis non erat porta luctus. Donec in facilisis felis. Vestibulum vitae purus a nisi ornare faucibus. Cras at justo nunc.</p>
             </ScrollView>
         )
};

export default TermsOfServicePage;
