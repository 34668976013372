import { LoadPanel } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useStore } from '../../../app/stores/store'
import NotificationPanel from '../../../app/layout/notificationPanel/NotificationPanel'

export default observer(function DashboardPage() {
    const { notificationStore } = useStore()

    useEffect(() => {

    }, [notificationStore])

    if (notificationStore.loading) {
        return <LoadPanel visible={true} />
    }

    return (
        <div className='content-block'>
            <h2>Here it goes my dashboard info.</h2>
            <NotificationPanel />
        </div>
    )
})