import Box, { Item } from 'devextreme-react/box'
import { Button } from 'devextreme-react/button'
import { confirm } from 'devextreme/ui/dialog'
import React, { useState } from 'react'
import { formatDateToDisplay } from '../../../app/common/utils/formatDate'
import { IClearence } from '../../../app/models/clearences/clearences'
import PortAuthorityView from './port/PortAuthorityView'

interface Props {
    deleteClearance: (clearence: IClearence) => void
    editClearance: (clearence: IClearence) => void
    clearence: IClearence
}

export default function BucketListItem({ clearence, deleteClearance, editClearance }: Props) {

    const [expanded, setExpanded] = useState<Boolean>(false)

    function handleExpanded() {
        setExpanded(!expanded)
    }

    function handleEdit() {
        editClearance(clearence)
    }

    function handleDelete() {
        var question = `Are you sure that you want to delete clearance ${clearence?.transactionId?.prefix}-${clearence?.transactionId?.value}?`
        let result = confirm(question, "Please confirm action")
        result.then((dialogResult) => {            
            if (dialogResult){
                deleteClearance(clearence)
            }
        })        
    }

    return (
        <div className={`clearance-header ${expanded ? "clearance-expanded" : ""}`}>
            <Box
                className='clearance-center'
                direction="row"
                width="100%">
                <Item ratio={0} baseSize={250}>
                    <div className='clearance-center'>
                        <b>{clearence?.type === 1 ? 'Declaration' : 'Port Clearance'}: {clearence?.transactionId?.prefix}-{clearence?.transactionId?.value}</b>
                    </div>
                </Item>
                <Item ratio={1}>
                    <div className='clearance-center'>
                        <b>Route: {clearence?.route}</b>
                    </div>
                </Item>
                <Item
                    ratio={0}
                    baseSize="150">
                    <div className="clearance-buttons">
                        {expanded && <Button icon="edit" onClick={handleEdit} />}
                        {expanded && <Button icon="trash" onClick={handleDelete} />}
                        <Button icon={expanded ? "close" : "chevrondown"} onClick={handleExpanded} />
                    </div>
                </Item>
            </Box>

            {(expanded && clearence?.type === 2) &&
                <>
                    <Box
                        className='clearance-center clearance-line'
                        direction="row"
                        width="100%">
                        <Item ratio={1}>
                            <PortAuthorityView isEntry={false} clearence={clearence} />
                        </Item>
                        <Item ratio={1}>
                            <PortAuthorityView isEntry={true} clearence={clearence} />
                        </Item>
                    </Box>
                    <Box
                        className='clearance-center clearance-line'
                        direction="row"
                        width="100%">
                        <Item ratio={1}>
                            <div>
                                <p>Requested By: {clearence?.requestedBy}</p>
                            </div>
                        </Item>
                        <Item ratio={1}>
                            <div>
                                <p>Requested On: {formatDateToDisplay(clearence?.requested)}</p>
                            </div>
                        </Item>
                    </Box>
                </>
            }
        </div>
    )
}

