import { Tag, TagCategory, TagType } from "../models/tag"
import { useStore } from '../stores/store'
import { v4 as uuid } from "uuid"
import DataSource from "devextreme/data/data_source"

export default function useTags(category: TagCategory, type: TagType, onTagsChanged: (tags: Tag[]) => void) {
    const { organisationStore: { organisation } } = useStore()
    const allTags: Tag[] = getAllTagsOptions()
    const tagBoxData = new DataSource(allTags.map(tag => tag.description))

    const customTagCreated = (newTagText: string) => {
        tagBoxData.store().insert(newTagText)
        tagBoxData.reload()
    }

    // TODO: check if useCallBack here will remove some renders
    const tagBoxValueChanged = (tagTexts: string[]) => {
        let tagObjects = getSelectedTags(tagTexts)
        onTagsChanged(tagObjects)
    }

    const getSelectedTags = (selectedTags: string[]): Tag[] => {
        let tagObjects: Tag[] = []

        selectedTags.forEach(tagString => {
            let tag = allTags.find(tag => tag.description === tagString)
            if (!tag) {
                tagObjects.push({
                    id: uuid(),
                    category: category,
                    type: type,
                    description: tagString,
                    isNew: true
                })
            }
            else {
                tagObjects.push({
                    id: tag.id,
                    category: tag.category,
                    type: tag.type,
                    description: tag.description,
                    isNew: false
                })
            }
        })

        return tagObjects
    }

    function getAllTagsOptions(): Tag[] {
        if (!organisation?.tags || organisation?.tags.length === 0) return []

        const tagsToReturn = organisation.tags.filter(tag => tag.category === category)
        return tagsToReturn ? tagsToReturn : []
    }

    return { tagBoxData, tagBoxValueChanged, customTagCreated }
}


