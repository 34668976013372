import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { Account, Team } from "../models/account"

export default class AccountStore {
    account: Account | undefined = undefined;
    teams: Team[] | undefined = undefined
    loadingAccount = false;

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingAccount = (state: boolean) => {
        this.loadingAccount = state
    }

    getAccount = async (id: string) => {
        try {
            this.setLoadingAccount(true)

            const response = await agent.Account.get(id)

            if (response.data)
                return response.data

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingAccount(false)
            })
        }
    }

    getTeams = async (accountId: string): Promise<Team[]> => {
        try {
            this.setLoadingAccount(true)

            const response = await agent.Account.getTeams(accountId)

            runInAction(() => {
                this.teams = response.data
            })

            return response.data

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingAccount(false)
            })
        }
    }

}