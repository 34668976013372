import React, { useCallback } from 'react'
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores/store"
import './verifyAccount.css'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'devextreme-react'
import notify from 'devextreme/ui/notify'

export default observer(function VerifyAccount() {
    const { userStore, commonStore } = useStore()
    const { logout } = useAuth0()

    const refreshPage = () => {
        window.location.reload()
    }

    const logOut = useCallback(() => {
        commonStore.setToken(null)
        logout({ returnTo: window.location.origin })
    }, [commonStore, logout])

    function handleResendVerification() {
        userStore.resendEmailConfirm().then(() => {
            notify("Email verification has been sent", "success")
        })
    }

    if (!userStore.user) return <></>

    return (
        <div className='centered'>
            <h3 >We are happy that you have signed up for<br /><a href={window.location.origin} color="inherit" target="_blank" rel="noopener noreferrer" >
                mycustomclouds.com</a>{' '}
            </h3>
            <h4 >
                To start using the system, please verify your account by confirming your email address.
            </h4>
            <h5 >
                Please check your {' '}{userStore.user.email}{' '}inbox and once confirmed, <button className='refresh-button' onClick={refreshPage}>refresh</button > your screen.
            </h5>
            <p >
                {'('}Or <button className='refresh-button small' onClick={logOut}>logout</button > this user.{')'}
            </p>
            <Button icon='email' type='default' text='Resend Registration Email' onClick={() => handleResendVerification()} />
        </div>
    )
})
