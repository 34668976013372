import Button from 'devextreme-react/button'
import Drawer from 'devextreme-react/drawer'
import ScrollView from 'devextreme-react/scroll-view'
import Header from '../header/Header'
import SideNavigationMenu from '../sideNavigationMenu/SideNavigationMenu'
import Footer from '../footer/Footer'
import Toolbar, { Item } from 'devextreme-react/toolbar'
import React, { useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router'
import './sideNavInnerToolbar.css'
import { useScreenSize } from '../../common/utils/mediaQuery'
import { Template } from 'devextreme-react/core/template'
import { useMenuPatch } from '../../common/utils/useMenuPatch'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores/store'


interface Props {
    title: string,
    children: any
}

export default observer(function SideNavInnerToolbar({ title, children }: Props) {
    const scrollViewRef = useRef<ScrollView>(null)
    const history = useHistory()
    const { isXSmall, isLarge } = useScreenSize()
    const [patchCssClass, onMenuReady] = useMenuPatch()
    const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed)
    const { userStore } = useStore()

    const toggleMenu = useCallback(({ event }) => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.Opened
                : MenuStatus.Closed
        )
        event.stopPropagation()
    }, [])

    const temporaryOpenMenu = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.TemporaryOpened
                : prevMenuStatus
        )
    }, [])

    const onOutsideClick = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
                ? MenuStatus.Closed
                : prevMenuStatus
        )
        return true
    }, [isLarge])

    const onNavigationChanged = useCallback(({ itemData: { path }, event, node }) => {
        if (menuStatus === MenuStatus.Closed || !path || node.selected) {
            event.preventDefault()
            return
        }

        history.push(path)
        scrollViewRef.current?.instance.scrollTo(0)

        if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
            setMenuStatus(MenuStatus.Closed)
            event.stopPropagation()
        }
    }, [history, menuStatus, isLarge])

    const showMenu = (userStore.user?.appMetadata?.organisations !== undefined && userStore.user.appMetadata?.organisations?.length > 0) ? 'menu' : undefined

    return (
        <div className={'side-nav-inner-toolbar'}>
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position={'before'}
                closeOnOutsideClick={onOutsideClick}
                openedStateMode={isLarge ? 'shrink' : 'overlap'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={!showMenu ? 0 : isXSmall ? 0 : 60}
                maxSize={250}
                shading={isLarge ? false : true}
                opened={menuStatus === MenuStatus.Closed ? false : true}
                template={showMenu}

            >
                <div className={'container'}>
                    <Header
                        menuToggleEnabled={isXSmall}
                        toggleMenu={toggleMenu}
                    />
                    <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
                        <div className={'content'}>
                            {React.Children.map(children, item => {
                                return item?.type !== Footer && item
                            })}
                        </div>
                        <div className={'content-block'}>
                            {React.Children.map(children, item => {
                                return item?.type === Footer && item
                            })}
                        </div>
                    </ScrollView>
                </div>
                {(showMenu === 'menu') && (
                    <Template name={'menu'}>

                        <SideNavigationMenu
                            compactMode={menuStatus === MenuStatus.Closed}
                            selectedItemChanged={onNavigationChanged}
                            openMenu={temporaryOpenMenu}
                            onMenuReady={onMenuReady}
                        >
                            <Toolbar id={'navigation-header'}>
                                {
                                    !isXSmall &&
                                    <Item
                                        location={'before'}
                                        cssClass={'menu-button'}
                                    >
                                        <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                                    </Item>
                                }
                                <Item location={'before'} cssClass={'header-title'} text={title} />
                            </Toolbar>
                        </SideNavigationMenu>
                    </Template>
                )}
            </Drawer>
        </div>
    )
})

const MenuStatus = {
    Closed: 1,
    Opened: 2,
    TemporaryOpened: 3
}
