import React from 'react'
import { observer } from "mobx-react-lite"
import { PortClearence } from '../../../../app/models/clearences/portClearence'
import { customsRoutesOptions } from '../../../../app/common/options/customsRoutesOptions'
import PBNDetails from './PBNDetails'
import GMRDetails from './GMRDetails'

interface Props {
    portClearence: PortClearence
}

export default observer(function PortDeclarationDetails({ portClearence }: Props) {

    const getPortAuthority = (isEntry: Boolean) => {
        const entryRoute = customsRoutesOptions.find(x => x.description === portClearence.route)
        var portAuthority
        switch (isEntry ? entryRoute?.portEntryAuth : entryRoute?.portExitAuth) {
            case "PBN": {
                portAuthority = <PBNDetails portClearence={portClearence} isEntry={isEntry} />
                break
            }
            case "GMR": {
                portAuthority = <GMRDetails portClearence={portClearence} isEntry={isEntry} />
                break
            }
            default: {
                //statements; 
                break
            }
        }
        return portAuthority
    }

    return (
        <>
            {/* Get the exit port Authority */}
            {getPortAuthority(false)}
            {/* Get the entry port Authority */}
            {getPortAuthority(true)}
        </>
    )
})



