
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../app/stores/store'
import { history } from '../../..'
import { Button, DropDownButton, Form, LoadPanel, Popup } from 'devextreme-react'
import { BucketFormValues, TransactionId } from '../../../app/models/bucket'
import { GroupItem, Item, Label } from 'devextreme-react/form'
import { Tag, TagCategory, TagType } from '../../../app/models/tag'
import TeamsTagBox from '../../../app/layout/teamsTagBox/TeamsTagBox'
import CustomTagBox from '../../../app/layout/customTagBox/CustomTagBox'
import notify from 'devextreme/ui/notify'
import './bucketDetails.css'
import BucketFormItem from './BucketFormItem'
import { ClearenceType, IClearence } from '../../../app/models/clearences/clearences'
import { PortClearence } from '../../../app/models/clearences/portClearence'
import { formatDateToDisplay } from '../../../app/common/utils/formatDate'
import BucketListItem from './BucketListItem'

export default observer(function BucketDetails() {
    const { organisationStore, bucketStore, customerStore, userStore, portClearenceStore } = useStore()
    const { createBucket, updateBucket, loadBucket, setClearence } = bucketStore

    const { id } = useParams<{ id: string }>()

    const [bucket, setBucket] = useState<BucketFormValues>(
        new BucketFormValues()
    )

    const [popupVisible, setPopupVisible] = useState<boolean>(false)

    const iniBucket = () => {
        setBucket({
            ...bucket,
            organisationId: organisationStore.organisation?.id,
            organisation: {
                id: organisationStore.organisation?.id ?? '',
                name: organisationStore.organisation?.name ?? ''
            }
        })
    }

    useEffect(() => {
        // Get the organisations if not loaded..
        if (!organisationStore.organisation &&
            userStore.user?.appMetadata?.organisations &&
            userStore.user?.appMetadata?.organisations.length > 0) {
            const firstOrgId = userStore.user?.appMetadata?.organisations[0]?.id
            organisationStore.getOrganisation(firstOrgId)
        }
        // Get the customers if not loaded...
        if (!customerStore.customerList) {
            customerStore.getCustomers()
        }
        // Get the bucket if edit...
        if (id) {
            loadBucket(id).then(bucket => {
                setBucket(new BucketFormValues(bucket))
            })
        } else {
            iniBucket()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, loadBucket, organisationStore, customerStore, userStore, organisationStore.organisation])

    if (id && bucketStore.loadingBucket) return <LoadPanel visible={true} />

    const formatTransactionId = (transactionId: TransactionId): string => {
        return `${transactionId.prefix}-${transactionId.value}`
    }

    function onTeamsChanged(teamIds: string[]) {
        setBucket({ ...bucket, teams: teamIds })
    }

    const onTagsChanged = (tags: Tag[]) => {
        setBucket({ ...bucket, tags })
    }

    function handleFormClose() {
        history.push('/buckets')
    }

    function handleClosePopUp() {
        bucketStore.resetMrns()
        setPopupVisible(false)
    }

    function handleSaveButton(e: any) {
        var validation = e?.validationGroup?.validate()
        if (validation?.isValid) {
            handleFormSubmit()
        }
    }

    function addNewTagsToOrganisation(tags?: Tag[]) {
        if (!tags) return
        // No new tags to be added
        if (tags.findIndex(tag => tag.isNew && tag.isNew === true) === -1) return

        tags.forEach(tag => {
            if (tag.isNew) {
                organisationStore.organisation?.tags?.push({ ...tag, isNew: false })
            }
        })
    }

    function handleFormSubmit() {
        if (!bucket?.id) {
            createBucket(bucket!.organisationId!, bucket)
                .then((bucketResponse) => {
                    if (bucketResponse) {
                        let bucketObj = { ...bucketResponse, customerId: bucket?.customerId }
                        setBucket(bucketObj)
                        addNewTagsToOrganisation(bucket?.tags)
                        // redirect here....
                        notify('Bucket created', 'success')
                        history.push(`/edit-custom-bucket/${bucketObj.id}`)
                    }
                })
                .catch((error) => {
                    notify('Bucket create error', 'error')
                    console.error('Bucket create error', error)
                })
        } else {
            updateBucket(bucket!.organisationId!, bucket).then(() => {
                notify('Bucket updated', 'success')
                addNewTagsToOrganisation(bucket?.tags)
            }).catch(() => {
                notify('Bucket update error', 'error')
            })
        }
    }

    function onButtonClick(e: any) {
        addDeclaration(2)
    }

    function onItemClick(e: any) {
        addDeclaration(e.itemData.id)
    }

    function onHidingPopup(e: any) {
        bucketStore.resetMrns()
        setClearence(undefined)
        setPopupVisible(false)
    }

    function onShowingPopup(e: any) {

    }

    function addDeclaration(e: number) {
        if (e === 2) {

            setClearence(new PortClearence())

            setPopupVisible(true)
        }
        if (e === 1) notify('Declaration functionality is not enable yet.', 'error', 1200)
    }

    function handleDeleteClearance(clearence: IClearence) {
        if (clearence.type === ClearenceType.Port && bucket.id) {
            portClearenceStore.deletePortClearence(bucket.id, clearence.id).then(() => {
                bucketStore.deleteClearence(clearence.id)
            })
        } else if (clearence.type === ClearenceType.Declaration && bucket.id) {
            // TODO: Add handler here...
        }
    }

    function handleEditClearance(clearence: IClearence) {
        setClearence(clearence)
        setPopupVisible(true)
    }

    const declarationType = [
        { id: 2, name: 'Port Clearance', icon: 'globe' },
        { id: 1, name: 'Declaration', icon: 'rename' },
    ]

    const colCountByScreen = {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2
    }

    return (
        <div className='content-block'>
            <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
                <h4 style={{ margin: '15px 5px 15px 30px' }}>{bucket?.transactionId ? `Bucket: ${formatTransactionId(bucket.transactionId)}` : "Create new bucket"}</h4>
            </div>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <Form
                        id={'form'}
                        formData={bucket}
                        labelLocation={'top'}
                        colCountByScreen={colCountByScreen}
                        showValidationSummary={true}
                        validationGroup={'bucketFormValidation'}
                    >
                        <GroupItem caption={'Bucket Details'} >
                            <Item dataField="customerId"
                                editorType="dxSelectBox"
                                text={"Customer"}
                                editorOptions={{
                                    value: bucket?.customerId,
                                    items: customerStore.customerList,
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    placeholder: "Select a customer..."
                                }}>
                                <Label text="Customer" />
                            </Item>
                            <Item caption={"Teams"}>
                                <Label text="Teams" />
                                <TeamsTagBox
                                    teamIds={bucket?.teams}
                                    onTeamsChanged={onTeamsChanged}
                                />
                            </Item>
                            <Item caption={"Tags"}>
                                <Label text="Tags" />
                                <CustomTagBox
                                    category={TagCategory.Bucket}
                                    type={TagType.User}
                                    tags={bucket?.tags ?? []}
                                    onTagsChanged={onTagsChanged}
                                    acceptCustomValue={true}
                                    hideSelectedItems={true}
                                />
                            </Item>
                            <Item>
                                <div className='default-dropdown-button-table default-dropdown-button-w-100'>
                                    <div>
                                        <Button
                                            icon="check"
                                            type="success"
                                            stylingMode="contained"
                                            validationGroup='bucketFormValidation'
                                            onClick={(e) => handleSaveButton(e)}
                                        />{' '}
                                        <Button
                                            icon="close"
                                            type="danger"
                                            stylingMode="contained"
                                            onClick={() => handleFormClose()}
                                        />
                                    </div>
                                    {(bucket?.id) &&
                                        <div className='default-dropdown-button-table-cell default-dropdown-button-tar'>
                                            <DropDownButton
                                                className='default-dropdown-button'
                                                splitButton={true}
                                                useSelectMode={false}
                                                text="Port Clearance"
                                                icon="plus"
                                                items={declarationType}
                                                displayExpr="name"
                                                keyExpr="id"
                                                onButtonClick={onButtonClick}
                                                onItemClick={onItemClick}
                                            />
                                        </div>
                                    }
                                </div>
                            </Item>
                        </GroupItem>
                        <GroupItem caption={'Info'} >
                            <p><b>{`Organisation: ${bucket?.organisation?.name ?? ''}`} </b></p>
                            <p><b>{`Bucket type: ${bucket?.customerId ? 'Single' : 'Multi'}`} </b></p>
                            <br />
                            <p>{`Created by: ${bucket?.createdBy ?? ''}`} </p>
                            <p>{`Created at: ${formatDateToDisplay(bucket?.created)}`} </p>
                            <p>{`Requested by: ${bucket?.requestedBy ?? ''}`}  </p>
                            <p>{`Requested at: ${formatDateToDisplay(bucket?.requested)}`} </p>
                            <p>{`Last modified by: ${bucket?.lastModifiedBy ?? ''}`}  </p>
                            <p>{`Last modified at: ${formatDateToDisplay(bucket?.lastModified)}`} </p>
                        </GroupItem>
                    </Form>
                    <Popup
                        title={bucketStore.clearance?.type === ClearenceType.Declaration ? "Declaration Clearance" : "Port Clearance"}
                        showTitle={true}
                        fullScreen={true}
                        visible={popupVisible}
                        showCloseButton={true}
                        onShowing={onShowingPopup}
                        onHiding={onHidingPopup}
                    >
                        {bucketStore.clearance ? <BucketFormItem closePopUp={handleClosePopUp} bucketId={id} /> : <LoadPanel />}
                    </Popup>
                    <div id="accordion">
                        {
                            bucketStore.bucket?.clearances?.map((c) => {
                                return <BucketListItem key={c.id} clearence={c} deleteClearance={handleDeleteClearance} editClearance={handleEditClearance} />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})