import DashboardPage from '../../features/dashbord/dashboardPage/DashboardPage'
import ProfilePage from '../../features/profile/profilePage/ProfilePage'
import OrganisationPage from '../../features/organisation/organisationPage/OrganisationPage'
import AddressPage from '../../features/address/addressPage/AddressPage'
import { withNavigationWatcher } from '../context/NavigationContext'
import CreateAddressForm from '../../features/address/createAddressForm/CreateAddressForm'
import CustomerPage from '../../features/customer/customerPage/CustomerPage'
import CreateCustomerForm from '../../features/customer/createCustomerForm/CreateCustomerForm'
import SupplierPage from '../../features/supplier/supplierPage/SupplierPage'
import CreateSupplierForm from '../../features/supplier/createSupplierForm/CreateSupplierForm'
import BucketPage from '../../features/bucket/bucketPage/BucketPage'
//import CreateCustomBucketForm from '../../features/bucket/createCustomBucketForm/CreateCustomBucketForm'
import BucketDetails from '../../features/bucket/customsBucket/BucketDetails'


const routes = [
    {
        path: '/organisation',
        component: OrganisationPage
    },
    {
        path: '/profile',
        component: ProfilePage
    },
    {
        path: '/buckets',
        component: BucketPage
    },
    {
        path: '/create-custom-bucket',
        component: BucketDetails
    },    
    {
        path: '/edit-custom-bucket/:id',
        component: BucketDetails
    },
    {
        path: '/customers',
        component: CustomerPage
    },
    {
        path: '/create-customer',
        component: CreateCustomerForm
    },
    {
        path: '/edit-customer/:id',
        component: CreateCustomerForm
    },
    {
        path: '/suppliers',
        component: SupplierPage
    },
    {
        path: '/create-supplier',
        component: CreateSupplierForm
    },
    {
        path: '/edit-supplier/:id',
        component: CreateSupplierForm
    },
    {
        path: '/addresses',
        component: AddressPage
    },
    {
        path: '/dashboard',
        component: DashboardPage
    },
    {
        path: '/create-address',
        component: CreateAddressForm
    },
    {
        path: '/edit-address/:id',
        component: CreateAddressForm
    }
]

export default routes.map(route => {
    return {
        ...route,
        component: withNavigationWatcher(route.component)
    }
})
