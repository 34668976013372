//export type TagTypes = 'customer' | 'supplier' | 'address'

export enum TagType {
    System = 1,
	User = 2
}

export enum TagCategory {
    Customer = 1,
    Supplier = 2,
    Address = 3,
    Bucket = 4,
    Clearence = 5,
    ClearencePort = 6
}

export interface Tag {
    id?: string,
    description: string,
    category: number,
    type: number,
    isNew?: boolean    
}

export class Tag implements Tag {
    constructor(init: TagFormValues) {
        Object.assign(this, init)
    }
}

export class TagFormValues {
    id?: string = undefined
    description: string = ""
    category: string = ""
    type: string = ""
    isNew: boolean = false

    constructor(tag?: TagFormValues) {
        if (tag) {
            this.id = tag.id
            this.description = tag.description
            this.category = tag.category
            this.type = tag.type
            this.isNew = tag.isNew
        }
    }
}