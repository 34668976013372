import { Address, AddressResponse } from './address'
import { Contact } from './contact'
import { Currency } from './currency'
import { Tag } from './tag'
import { VATMpet } from './vat'

export const CustomerStatus = [
    { value: -1, description: 'Inactive' },
    { value: 1, description: 'Active' },
]

export interface Customer {
    organisationId?: string,
    id?: string,
    name: string,
    code?: string,
    eori?: string,
    currencyCode: string,
    contact?: Contact,
    address: Address,
    billingAddress: Address,
    //billable: boolean
    //defaultTaxRate: string
    tax?: CustomerTaxInfo,
    status: number,
    //accountLink: boolean
    tags?: Tag[]
}

export class Customer implements Customer {
    constructor(init: CustomerFormValues) {
        Object.assign(this, init)
    }
}

export interface CustomerTaxInfo {
    taxId?: string,
    tax?: {
        id?: string,
        code?: string,
        description?: string,
        value?: string,
    },
    taxIdentifierLabel1?: string,
    taxIdentifierLabel2?: string,
    taxNumber1?: string,
    taxNumber2?: string
}

export interface CustomerResponse extends Customer {
    currency: Currency,
    vat: VATMpet,
    address: AddressResponse,
    billingAddress: AddressResponse,
    organisation?: {
        id: string,
        name: string
    },
}

export class CustomerAddressFormValues {
    name: string = ""
    region: string = ""
    countryISOCode: string = "IE"
    code: string = ""
    city: string = ""
    postcode: string = ""
    address1: string = ""
    address2: string = ""
    type: number = 0
    status: number = 1
    lat?: number = 0
    lon?: number = 0

    constructor(customerAddress?: CustomerAddressFormValues) {
        if (customerAddress) {
            this.name = customerAddress.name
            this.region = customerAddress.region
            this.countryISOCode = customerAddress.countryISOCode
            this.code = customerAddress.code
            this.city = customerAddress.city
            this.postcode = customerAddress.postcode
            this.address1 = customerAddress.address1
            this.address2 = customerAddress.address2
            this.type = customerAddress.type
            this.status = customerAddress.status
            this.lat = customerAddress.lat
            this.lon = customerAddress.lon
        }
    }

}

export class CustomerbillingAddressFormValues {
    name: string = ""
    region: string = ""
    countryISOCode: string = "IE"
    code: string = ""
    city: string = ""
    postcode: string = ""
    address1: string = ""
    address2: string = ""
    type: number = 0
    status: number = 1

    constructor(customerAddress?: CustomerbillingAddressFormValues) {
        if (customerAddress) {
            this.name = customerAddress.name
            this.region = customerAddress.region
            this.countryISOCode = customerAddress.countryISOCode
            this.code = customerAddress.code
            this.city = customerAddress.city
            this.postcode = customerAddress.postcode
            this.address1 = customerAddress.address1
            this.address2 = customerAddress.address2
            this.type = customerAddress.type
            this.status = customerAddress.status
        }
    }

}



export class CustomerFormValues {
    organisationId?: string = ""
    id?: string = undefined
    name: string = ""
    code?: string = ""
    address: CustomerAddressFormValues = new CustomerAddressFormValues()
    billingAddress: CustomerbillingAddressFormValues = new CustomerbillingAddressFormValues()
    status: number = 1
    //accountLink: false,
    currencyCode: string = "EUR"
    eori?: string = ""
    tags?: Tag[] = []
    tax?: CustomerTaxInfo

    constructor(customer?: CustomerFormValues | Customer | undefined) {
        if (customer) {
            this.organisationId = customer.organisationId
            this.id = customer.id
            this.code = customer.code
            this.name = customer.name
            this.address = customer.address
            this.billingAddress = customer.billingAddress
            this.status = customer.status
            this.currencyCode = customer.currencyCode
            this.eori = customer.eori
            this.tags = customer.tags
            this.tax = customer.tax
        }
    }


}