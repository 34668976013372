import LoadPanel from 'devextreme-react/load-panel'
import React, { useEffect } from 'react'
import { useStore } from '../stores/store'
import UnauthenticatedContent from '../routes/UnauthenticatedContent'
import AuthenticatedContent from '../routes/AuthenticatedContent'
import { useScreenSizeClass } from '../common/utils/mediaQuery'
import { useAuth0 } from '@auth0/auth0-react'

function App() {
  const { commonStore: { token, setToken } } = useStore()
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (isAuthenticated && !token) {
      getAccessTokenSilently()
        .then((e) => {
          setToken(e)
        })
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently, setToken, token])

  if (isLoading) {
    return <LoadPanel visible={true} />
  }

  if (isAuthenticated) {
    return <AuthenticatedContent />
  }

  return <UnauthenticatedContent />
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass()

  return (
    <div className={`app ${screenSizeClass}`}>
      <App />
    </div>
  )

}