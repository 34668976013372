import React, { useState, createContext, useContext, useEffect } from 'react'

interface NavigationData {
    currentPath: string
}

type NavigationContextType = {
    setNavigationData?: (data: NavigationData) => Promise<void>
    navigationData: NavigationData
}

const NavigationContext = createContext<NavigationContextType>({ navigationData: { currentPath: '' } })
const useNavigation = () => useContext(NavigationContext)

function NavigationProvider(props: any) {
    const [navigationData, setNavigationData] = useState({})

    return (
        <NavigationContext.Provider
            value={{ navigationData, setNavigationData }}
            {...props}
        />
    )
}

function withNavigationWatcher(Component: any) {
    return function (props: any) {
        const { path } = props.match
        const { setNavigationData } = useNavigation()

        useEffect(() => {
            if (setNavigationData) { setNavigationData({ currentPath: path }) }
        }, [path, setNavigationData])

        return React.createElement(Component, props)
    }
}

export {
    NavigationProvider,
    useNavigation,
    withNavigationWatcher
}
