import React, { useState } from "react"
import Form, { ButtonItem, ButtonOptions } from 'devextreme-react/form'
import { useAuth0 } from '@auth0/auth0-react'
import { UserPlan } from '../../../app/models/user'
import SingleCard from "../../../app/layout/singleCard/SingleCard"
import CreateAccountForm from "../../account/createAccountForm/CreateAccountForm"
import Box, {
    Item,
} from 'devextreme-react/box'
import PlanCard from '../../account/subscription/planCard/PlanCard'
import useRouterQueryParam from '../../../app/hooks/useRouterQueryParam'
import { useHistory } from 'react-router-dom'

export default function HomePage() {
    const history = useHistory()
    const { loginWithRedirect } = useAuth0()

    const [selectedPlan, setSelectedPlan] = useState<UserPlan | undefined>()

    const supportSignUp = useRouterQueryParam().get("supportSignUp") as string
    const success = useRouterQueryParam().get("success") as string

    if (supportSignUp === 'true' && success === 'true') {
        loginWithRedirect()
    }

    function selectPlan(plan: UserPlan | undefined): void {
        setSelectedPlan(plan)
    }

    function onCreatedAccount() {
        setTimeout(() => history.push('/registration-thank-you'), 1000)
    }

    if (selectedPlan !== undefined) {
        return (<SingleCard title="Create Account" className="with-footer single-card create-account-card"  >
            <CreateAccountForm plan={selectedPlan} onRedirect={onCreatedAccount} onChangePlan={() => selectPlan(undefined)} />
        </SingleCard>)
    }

    return (
        <React.Fragment>
            <Form >
                <ButtonItem>
                    <ButtonOptions
                        type={'default'}
                        useSubmitBehavior={false}
                        onClick={loginWithRedirect}
                    >
                        <span className="dx-button-text">
                            Login
                        </span>
                    </ButtonOptions>
                </ButtonItem>
            </Form>
            <Box
                direction="row"
                width="100%"
                height={500}>
                <Item ratio={1}>
                    <PlanCard title='Plan A' description='This Plan includes 1h support' price={39.99}
                        onSelected={selectPlan} />
                </Item>
                <Item ratio={1}>
                    <PlanCard title='Plan B' description='This Plan includes 4h support' price={59.99}
                        onSelected={selectPlan} />
                </Item>
                <Item ratio={1}>
                    <PlanCard title='Plan C' description='This Plan includes 24h support!!' price={89.99}
                        onSelected={selectPlan} />
                </Item>
            </Box>
        </React.Fragment>

    )
}