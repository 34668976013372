import { RouteComponentProps } from "react-router-dom"

export interface NavigationItem {
    text: string,
    path: string,
    icon?: string,
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}
/*
export const authenticatedNavigation: NavigationItem[] = [
    {
        text: 'Dashboard',
        path: '/dashboard',
        icon: 'home',
        component: withNavigationWatcher(DashboardPage)
    },
    {
        text: 'Organisation',
        path: '/organisation',
        icon: 'home',
        component: withNavigationWatcher(OrganisationPage)
    },
    {
        text: 'Profile',
        path: '/profile',
        icon: 'user',
        component: withNavigationWatcher(ProfilePage)
    },
    // {  
    //     text: 'Account',
    //     path: '/account',
    //     icon: 'user',
    //     component: withNavigationWatcher(AccountPage)
    // }
]
*/

export const unauthenticatedNavigation: NavigationItem[] = [
    // {  
    //     text: 'Landing',
    //     path: '/',
    //     component: Landing
    // }
]



export const navigation = [
    {
        text: 'Dashboard',
        path: '/dashboard',
        icon: 'home',

    },
    {
        text: 'Buckets',
        path: '/buckets',
        icon: 'product',

    },
    {
        text: 'Admin',
        icon: 'preferences',
        items: [
            // {
            //     text: 'Account',
            //     path: '/account'
            // },
            {
                text: 'Profile',
                path: '/profile'
            },
            {
                text: 'Organisation',
                path: '/organisation'
            },
            {
                text: 'Customers',
                path: '/customers'
            },
            {
                text: 'Addresses',
                path: '/addresses'
            },
            {
                text: 'Suppliers',
                path: '/suppliers'
            }
        ]
    }
]

