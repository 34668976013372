import React, { useEffect, useState } from 'react'
import Form, { Item, GroupItem, Label, TabbedItem, Tab, RequiredRule, TabPanelOptions } from 'devextreme-react/form'
import { useStore } from '../../../app/stores/store'
import { CustomerAddressFormValues, CustomerFormValues, CustomerStatus } from '../../../app/models/customer'
import { currencyOptions } from '../../../app/common/options/currencyOptions'
import { useHistory, useParams } from 'react-router-dom'
import { Button, LoadPanel } from 'devextreme-react'
import notify from 'devextreme/ui/notify'
import { countryOptions } from '../../../app/common/options/countryOptions'
import { Tag, TagCategory, TagType } from '../../../app/models/tag'
import { observer } from 'mobx-react-lite'
import CustomTagBox from '../../../app/layout/customTagBox/CustomTagBox'
import { Address } from '../../../app/models/address'
import { confirm } from 'devextreme/ui/dialog'
import './createCustomerForm.css'


function CreateCustomerForm() {
  const history = useHistory()

  const {
    organisationStore, userStore, customerStore,
    addressStore: { createAddress }
  } = useStore()

  const { id } = useParams<{ id: string }>()
  const iniCustValue: CustomerFormValues = {
    ...new CustomerFormValues(),
    organisationId: organisationStore.organisation?.id,
    currencyCode: organisationStore.organisation?.defaultCurrency?.code ?? "EUR",
    address: {
      ...new CustomerAddressFormValues(),
      countryISOCode: organisationStore.organisation?.address?.countryISOCode ?? "IE"
    },
    billingAddress: {
      ...new CustomerAddressFormValues(),
      countryISOCode: organisationStore.organisation?.address?.countryISOCode ?? "IE"
    },
    tax: {
      taxId: organisationStore.organisation?.listSettings?.vatRates ? organisationStore.organisation?.listSettings?.vatRates[0]?.id : '',
      taxIdentifierLabel1: organisationStore.organisation?.settings?.taxIdentifierLabel1,
      taxIdentifierLabel2: organisationStore.organisation?.settings?.taxIdentifierLabel2
    }
  }

  const [customer, setCustomer] = useState<CustomerFormValues>(iniCustValue)

  useEffect(() => {
    if (
      !organisationStore.organisation &&
      userStore.user?.appMetadata?.organisations &&
      userStore.user?.appMetadata?.organisations.length > 0) {
      const firstOrgId = userStore.user?.appMetadata?.organisations[0]?.id
      organisationStore.getOrganisation(firstOrgId)
    }

    if (id) {
      customerStore.getCustomer(id).then(customer => {
        setCustomer(new CustomerFormValues(customer))
      })
    }
  }, [id, customerStore, userStore.user, organisationStore])


  if (customerStore.loading || organisationStore.loadingOrganisation || !customer) {
    return <LoadPanel visible={true} />
  }


  function handleFormSubmit() {
    if (!customer.id) {
      customerStore.createCustomer(customer.organisationId!, customer).then(async () => {
        notify('Customer created', 'success')
        addNewTagsToOrganisation(customer.tags)

        let result = await confirm("<p>Would you like to save the customer address as a service address?</p>", "Address")
        if (result)
          saveAddressAsServiceAddress()

        history.goBack()
      }).catch((e) => {
        notify(`Customer create error: ${e[0]}`, 'error')
      })
    }
    else {
      customerStore.updateCustomer(customer.organisationId!, customer).then(() => {
        notify('Customer updated', 'success')
        addNewTagsToOrganisation(customer.tags)
        history.goBack()
      }).catch((e) => {
        notify(`Customer update error: ${e[0]}`, 'error')
      })
    }
  }

  function onTagsChanged(tags: Tag[]) {
    customer.tags = tags
  }

  function addNewTagsToOrganisation(tags?: Tag[]) {
    if (!tags) return

    // No new tags to be added
    if (tags.findIndex(tag => tag.isNew && tag.isNew === true) === -1) return

    tags.forEach(tag => {
      if (tag.isNew) {
        organisationStore.organisation?.tags?.push({ ...tag, isNew: false })
      }
    })
  }

  function handleFormClose() {
    history.goBack()
  }

  function handleCopyToBillingAddress() {
    customer.billingAddress = customer.address

    setCustomer(new CustomerFormValues(customer))

    notify('Address copied to billing address', 'success')
  }

  function handleSaveButton(e: any) {
    var validation = e?.validationGroup?.validate()
    if (validation?.isValid) {
      handleFormSubmit()
    }
  }

  function saveAddressAsServiceAddress() {
    let newAddress: Address = {
      ...customer.address,
      organisationId: customer.organisationId,
      eori: customer.eori,
      type: 2,
      status: 1,
      personId: customer.id,
    }

    createAddress(newAddress.organisationId!, newAddress).then((e) => {
      notify('Address created as service address', 'success')
    }).catch(e => {
      notify('Error creating address as service address', 'error')
    })
  }

  const onTabChange = (e: any) => {
    if (e.itemData.title === 'Customer') {
      var customerName = document.getElementsByName("name")
      customerName[0]?.focus()
    } else if (e.itemData.title === 'Address') {
      var customerAddress = document.getElementsByName("address.code")
      customerAddress[0]?.focus()
    } else if (e.itemData.title === 'Billing address') {
      var customerBilling = document.getElementsByName("billingAddress.code")
      customerBilling[0]?.focus()
    }
  }

  const onInitialized = () => {
    var elements = document.getElementsByName("name")
    elements[0]?.focus()
  }

  return (
    <div className='content-block'>
      <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
        <div className={'form-avatar'} style={{ margin: '5px 0px 5px 5px' }}>
        </div>
        <h4 style={{ margin: '15px 5px 15px 30px' }}>{customer.name ? customer.name : "Create new customer"}</h4>
      </div>
      <div className={'content-block'}>
        <Form
          id={'form'}
          formData={customer}
          labelLocation={'top'}
          showValidationSummary={true}
          validationGroup={'customerFormValidation'}
          tabIndex={0}
          onContentReady={onInitialized}
        >
          <TabbedItem >
            <TabPanelOptions deferRendering={false} tabIndex={0} onTitleClick={onTabChange} />
            <Tab title="Customer" icon="card" colCount={2}  >
              <GroupItem caption="Info" colCountByScreen={colCountByScreen} >
                <Item dataField="name" caption={"Name"} isRequired={true} >
                  <Label text="Name" />
                  <RequiredRule message="Name is required" />
                </Item>
                <Item dataField="code" caption={"Code"}>
                  <Label text="Account Code" />
                </Item>
                <Item dataField="eori" caption={"EORI"} data-test="eori" isRequired={true}>
                  <Label text="EORI" />
                  <RequiredRule message="EORI is required" />
                </Item>
                <Item
                  dataField="currencyCode"
                  editorType="dxSelectBox"
                  isRequired={true}
                  editorOptions={{
                    items: currencyOptions,
                    searchEnabled: true,
                    displayExpr: "name",
                    valueExpr: "code",
                    placeholder: "Select a currency..."
                  }}>
                  <Label text="Billing Currency" />
                </Item>
                <Item caption={"Tags"}>
                  <Label text="Tags" />
                  <CustomTagBox
                    category={TagCategory.Customer}
                    type={TagType.User}
                    tags={customer.tags ?? []}
                    onTagsChanged={onTagsChanged}
                    acceptCustomValue={true}
                    hideSelectedItems={true}
                  />
                </Item>
                <Item dataField="status"
                  editorType="dxSelectBox"
                  text={"Status"}
                  editorOptions={{
                    items: CustomerStatus,
                    searchEnabled: false,
                    displayExpr: "description",
                    valueExpr: "value",
                    placeholder: "Status..."
                  }}>
                  <Label text="Status" />
                </Item>
                <Item>
                  <Button
                    icon="check"
                    type="success"
                    stylingMode="contained"
                    validationGroup='customerFormValidation'
                    onClick={(e) => handleSaveButton(e)}
                  />{' '}
                  <Button
                    icon="close"
                    type="danger"
                    stylingMode="contained"
                    onClick={() => handleFormClose()}
                  />
                </Item>
              </GroupItem>
              <GroupItem caption="Tax Info" colCountByScreen={colCountByScreen}>

                <Item dataField="tax.taxIdentifierLabel1" caption={"Tax Label 1"}>
                  <Label text="Tax Label 1" />
                </Item>
                <Item dataField="tax.taxNumber1" caption={customer?.tax?.taxIdentifierLabel1}>
                  <Label text={customer?.tax?.taxIdentifierLabel1} />
                </Item>
                <Item dataField="tax.taxIdentifierLabel2" caption={"Tax Label 2"}>
                  <Label text="Tax Label 2" />
                </Item>
                <Item dataField="tax.taxNumber2" caption={customer?.tax?.taxIdentifierLabel2}>
                  <Label text={customer?.tax?.taxIdentifierLabel2} />
                </Item>
                <Item
                  dataField="tax.taxId"
                  editorType="dxSelectBox"
                  text={"Default VAT"}
                  editorOptions={{
                    items: organisationStore.organisation?.listSettings.vatRates,
                    searchEnabled: true,
                    displayExpr: "code",
                    valueExpr: "id",
                    placeholder: "Select a vat..."
                  }}>
                  <Label text="Default VAT" />
                </Item>
              </GroupItem>
            </Tab>


            <Tab title='Address' icon="map">
              <GroupItem caption='Address' colCountByScreen={colCountByScreen} >
                <Item dataField="address.code" caption={"Code"} isRequired={true}>
                  <Label text="Code" />
                </Item>
                <Item dataField="address.name" caption={"Name"} isRequired={true}>
                  <Label text="Name" />
                </Item>
                <Item dataField="address.address1" caption={"Address 1"} colSpan={2} isRequired={true}>
                  <Label text="Address 1" />
                  <RequiredRule message="Customer Address is required" />
                </Item>
                <Item dataField="address.address2" caption={"Address 2"} colSpan={2}>
                  <Label text="Address 2" />
                </Item>
                <Item dataField="address.city" title={"City"}>
                  <Label text="City" />
                </Item>
                <Item dataField="address.region" title={"Region"}>
                  <Label text="County/Region" />
                </Item>
                <Item dataField="address.postcode" text={"Postcode"}>
                  <Label text="Postcode" />
                </Item>
                <Item
                  dataField="address.countryISOCode"
                  editorType="dxSelectBox"
                  text={"Country"}
                  isRequired={true}
                  editorOptions={{
                    items: countryOptions,
                    searchEnabled: true,
                    displayExpr: "name",
                    valueExpr: "isoCode",
                    placeholder: "Select a country..."
                  }}>
                  <Label text="Country" />
                </Item>
                <Item dataField="address.lat" title={"Latitude"}>
                  <Label text="Latitude" />
                </Item>
                <Item dataField="address.lon" title={"Longitude"} colSpan={2} >
                  <Label text="Longitude" />
                </Item>
                <Item dataField="address.notes" title={"Notes"} colSpan={2} >
                  <Label text="Notes" />
                </Item>
                <Item>
                  <Button
                    icon="check"
                    type="success"
                    stylingMode="contained"
                    validationGroup='customerFormValidation'
                    onClick={(e) => handleSaveButton(e)}
                  />{' '}
                  <Button
                    icon="close"
                    type="danger"
                    stylingMode="contained"
                    onClick={() => handleFormClose()}
                  />
                  {'   '}
                  <Button
                    text="Copy to billing address"
                    type="default"
                    stylingMode="contained"
                    onClick={handleCopyToBillingAddress}
                    useSubmitBehavior={false}
                  />
                </Item>
              </GroupItem>
            </Tab>


            <Tab title='Billing address' icon="money">
              <GroupItem caption='Address' colCountByScreen={colCountByScreen} >
                <Item dataField="billingAddress.code" caption={"Code"} isRequired={true}>
                  <Label text="Code" />
                </Item>
                <Item dataField="billingAddress.name" caption={"Name"} isRequired={true}>
                  <Label text="Name" />
                </Item>
                <Item dataField="billingAddress.address1" caption={"Address 1"} colSpan={2} isRequired={true}>
                  <Label text="Address 1" />
                  <RequiredRule message="Billing Address is required" />
                </Item>
                <Item dataField="billingAddress.address2" caption={"Address 2"} colSpan={2}>
                  <Label text="Address 2" />
                </Item>
                <Item dataField="billingAddress.city" title={"City"}>
                  <Label text="City" />
                </Item>
                <Item dataField="billingAddress.region" title={"Region"} >
                  <Label text="County/Region" />
                </Item>
                <Item dataField="billingAddress.postcode" text={"Postcode"}>
                  <Label text="Postcode" />
                </Item>
                <Item
                  dataField="billingAddress.countryISOCode"
                  editorType="dxSelectBox"
                  text={"Country"}
                  isRequired={true}
                  editorOptions={{
                    items: countryOptions,
                    searchEnabled: true,
                    displayExpr: "name",
                    valueExpr: "isoCode",
                    placeholder: "Select a country..."
                  }}>
                  <Label text="Country" />
                </Item>
                <Item dataField="billingAddress.lat" title={"Latitude"}>
                  <Label text="Latitude" />
                </Item>
                <Item dataField="billingAddress.lon" title={"Longitude"} colSpan={2} >
                  <Label text="Longitude" />
                </Item>
                <Item dataField="billingAddress.notes" title={"Notes"} colSpan={2} >
                  <Label text="Notes" />
                </Item>
                <Item>
                  <Button
                    icon="check"
                    type="success"
                    stylingMode="contained"
                    validationGroup='customerFormValidation'
                    onClick={(e) => handleSaveButton(e)}
                  />{' '}
                  <Button
                    icon="close"
                    type="danger"
                    stylingMode="contained"
                    onClick={() => handleFormClose()}
                  />
                </Item>
              </GroupItem>
            </Tab>
          </TabbedItem>
        </Form>
      </div>
    </div>
  )
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 2,
  lg: 2
}

export default observer(CreateCustomerForm)