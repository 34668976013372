import { makeAutoObservable, reaction } from "mobx"
import { ServerError } from "../models/serverError"
import { store } from "./store"

export default class CommonStore {
    error: ServerError | null = null
    token: string | null = null
    appLoaded = false
    drawerOpen = false

    constructor() {
        makeAutoObservable(this)

        reaction(
            () => this.token,
            token => {
                if (token) {
                    store.userStore.getUser()
                } else {
                    store.userStore.logoutUser()
                }
                console.log('token: ', token)
            }
        )
    }

    setServerError = (error: ServerError) => {
        this.error = error
    }

    setToken = (token: string | null) => {
        this.token = token
    }

    setAppLoaded = () => {
        this.appLoaded = true
    }

    setDrawerOpen = (value: boolean) => {
        this.drawerOpen = value
    }
}