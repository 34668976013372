import agent from "../api/agent"
import { makeAutoObservable, runInAction } from "mobx"
import { DeclarationClearence } from "../models/clearences/clearences"

export default class DeclarationClearenceStore {
    loading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingDeclarationClearence = (state: boolean) => {
        this.loading = state
    }

    // inside of bucket?
    // getBuckets = async (bucketId: string) => {
    //     try {
    //         this.setLoadingDeclarationClearence(true)                        
    //         const response = await agent.DeclarationClearence.getAll(bucketId)

    //         if (response.data) {
    //             const tempArray: Bucket[] = []
    //             console.log('Bucket data', response.data)

    //             response.data.map((data: BucketResponse) => {
    //                 tempArray.push(this.createDeclarationObj(data))
    //             })

    //             runInAction(() => {
    //               this.bucketList = tempArray
    //             })
    //         }

    //     } catch (error) {
    //         console.log(error)
    //         throw error
    //     } finally {
    //         runInAction(() => {
    //             this.setLoadingDeclarationClearence(false)
    //         })
    //     }
    // }

    getDeclarationClearence = async (bucketId: string, clearanceId: string) => {
        try {
            this.setLoadingDeclarationClearence(true)
            
            const response = await agent.DeclarationClearence.get(bucketId, clearanceId)

            if (response.data)                
		        return response.data //this.createDeclarationObj(response.data);

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingDeclarationClearence(false)
            })
        }
    }

    deleteDeclarationClearence = async (bucketId: string, clearanceId: string) => {
        try {
            this.setLoadingDeclarationClearence(true)
            
            await agent.DeclarationClearence.delete(bucketId, clearanceId)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingDeclarationClearence(false)
            })
        }
    }

    updateDeclarationClearence = async (bucketId: string, clearanceId: string, declaration: DeclarationClearence) => {
        try {
            this.setLoadingDeclarationClearence(true)
            
            await agent.DeclarationClearence.update(bucketId, clearanceId, declaration)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingDeclarationClearence(false)
            })
        }
    }

    createDeclarationClearence = async (bucketId: string, declaration: DeclarationClearence) => {
        try {
            this.setLoadingDeclarationClearence(true)
            console.log("createDeclarationClearence", declaration)
            
            const response = await agent.DeclarationClearence.create(bucketId, declaration)

            // TODO: add the created declaration to the its bucket owner list
            console.log(response)
            //if (response.data) {                               

                // runInAction(() => {
                //     this.declarationList?.push(declarationInfo)
                // })

                // return declarationList;
            //}

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingDeclarationClearence(false)
            })
        }
    }

    // private createDeclarationObj(data: BucketResponse) : Bucket {
    //     let bucket: Bucket = {
    //         id: data.id,
    //         transactionId: data.transactionId,
    //         requestedBy: data.requestedBy,
    //         requestedDate: data.requestedDate,
    //         customerId: data.customer?.id,
    //         customerName: data.customerName,
    //         billTo: data.billTo,
    //         billToName: data.billToName,
    //         status: data.status,
    //         clearances: data.clearances,
    //         created: data.created,
    //         createdBy: data.createdBy,
    //         lastModified: data.lastModified,
    //         lastModifiedBy: data.lastModifiedBy,
    //         tags: data.tags,
    //         teams: data.teams,            
    //     }
        
    //     return bucket;
    // }

}