import React, { useRef, useCallback, useState } from 'react'
import './createAccountForm.css'
import { Link } from 'react-router-dom'
import {
    Form,
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule
} from 'devextreme-react/form'
import { useStore } from "../../../app/stores/store"
import { UserFormPlanSelection } from '../../../app/models/user'
import { Button, LoadIndicator } from 'devextreme-react'
import { observer } from 'mobx-react-lite'
import { UserPlan } from '../../../app/models/user'
import { LoadPanel } from "devextreme-react"
import { useAuth0 } from '@auth0/auth0-react'
import Popup from '../../../app/layout/popup/Popup'
import TermsOfServicePage from '../../policies/termsOfServicePage/TermsOfServicePage'
import PrivacyPolicyPage from '../../policies/privacyPolicyPage/PrivacyPolicyPage'

export interface CreateAccountFormProp {
    plan: UserPlan,
    onRedirect: Function,
    onChangePlan: Function
}

export default observer(function CreateAccountForm(prop: CreateAccountFormProp) {
    const [termsPopupOpen, setTermsPopupOpen] = useState(false)
    const [privacyPopupOpen, setPrivacyPopupOpen] = useState(false)

    const { userStore } = useStore()
    const { loginWithRedirect } = useAuth0()

    const formData = useRef<UserFormPlanSelection>(
        {
            email: "",
            password: "",
            appMetaData: {
                status: "unverified",
                plans: [
                    prop.plan
                ]
            }
        }
    )

    const onSubmit = useCallback((e) => {
        e.preventDefault()

        userStore.register(formData.current).then(() => {
            prop.onRedirect()
        }).catch(error => {
            // Display Errors here...
            console.log(error)
        })
    }, [userStore, prop])

    const confirmPassword = useCallback(({ value }) => {

        return value === formData.current.password
    }, [])

    const handleEditPlan = () => {
        prop.onChangePlan()
    }

    if (userStore.loadingCreate) {
        return <LoadPanel visible={true} />
    }

    const formatCurrency = new Intl.NumberFormat(
        'en-IE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    ).format

    return (
        <React.Fragment>
            <Popup height={600} width={700} visible={termsPopupOpen} onClosePopup={() => setTermsPopupOpen(false)} title='Terms of Service' children={<TermsOfServicePage />} />
            <Popup height={600} width={700} visible={privacyPopupOpen} onClosePopup={() => setPrivacyPopupOpen(false)} title='Privacy Policy' children={<PrivacyPolicyPage />} />
            <form className={'create-account-form'} onSubmit={onSubmit} >
                <div className='selected-plan-details'>
                    <div className='plan-details'>
                        <div className='plan-name'>{formData.current.appMetaData.plans[0].name}</div>
                        <div className='plan-price'>{formatCurrency(formData.current.appMetaData.plans[0].price!)}</div>
                    </div>
                    <Button
                        className='action-button'
                        type='normal'
                        icon='edit'
                        onClick={handleEditPlan}
                    />
                </div>
                <Form formData={formData.current} disabled={userStore.loadingCreate}>
                    <Item
                        dataField={'email'}
                        editorType={'dxTextBox'}
                        editorOptions={emailEditorOptions}
                    >
                        <RequiredRule message="Email is required" />
                        <EmailRule message="Email is invalid" />
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'password'}
                        editorType={'dxTextBox'}
                        editorOptions={passwordEditorOptions}
                    >
                        <RequiredRule message="Password is required" />
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'confirmedPassword'}
                        editorType={'dxTextBox'}
                        editorOptions={confirmedPasswordEditorOptions}
                    >
                        <RequiredRule message="Password is required" />
                        <CustomRule
                            message={'Passwords do not match'}
                            validationCallback={confirmPassword}
                        />
                        <Label visible={false} />
                    </Item>
                    <Item>
                        <div className='policy-info'>
                            By creating an account, you agree to the{" "}
                            <Link to="/" onClick={() => setTermsPopupOpen(true)}>Terms of Service</Link>{" "}and{" "}
                            <Link to="/" onClick={() => setPrivacyPopupOpen(true)}>Privacy Policy</Link>
                        </div>
                    </Item>



                    <ButtonItem>
                        <ButtonOptions
                            width={'100%'}
                            type={'default'}
                            useSubmitBehavior={true}
                        >
                            <span className="dx-button-text">
                                {
                                    userStore.loadingCreate
                                        ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                        : 'Create a new account'
                                }
                            </span>
                        </ButtonOptions>
                    </ButtonItem>
                    <Item>
                        <div className={'login-link'}>
                            Have an account? <Link to={'/'} onClick={loginWithRedirect}>Sign In</Link>
                        </div>
                    </Item>
                </Form>
            </form>
        </React.Fragment>
    )
})

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' }
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' }
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' }
