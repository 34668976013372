
import axios, { AxiosError, AxiosResponse } from "axios"
import { history } from "../.."
import { UserFormPlanSelection, User as UserModel } from "../models/user"
import { store } from "../stores/store"
import { ResultData } from "../models/response"
import notify from 'devextreme/ui/notify'
import { ServerError } from '../models/serverError'
import { VATMpet } from "../models/vat"
import { NominalCodeMpet } from '../models/nominalCode'
import { Address } from "../models/address"
import { Customer } from "../models/customer"
import { Supplier } from "../models/supplier"
import { IBucket } from "../models/bucket"
import { PortClearence } from "../models/clearences/portClearence"
import { DeclarationClearence } from "../models/clearences/clearences"


const bucketBaseURL = process.env.REACT_APP_BUCKET_API_SERVER_URL
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER_URL

axios.interceptors.request.use(config => {
    const token = store.commonStore.token
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
})

axios.interceptors.response.use(
    async response => {

        const pagination = response.headers["pagination"]
        if (pagination) {
            // Do something here to manage pagination...

            // response.data = new PaginatedResult(response.data, JSON.parse(pagination))
            // return response as AxiosResponse<PaginatedResult<any>>
        }

        response.data = new ResultData(response.data)

        return response as AxiosResponse<ResultData<any>>
    },
    (error: AxiosError) => {
        const { data, status, config, headers } = error.response!
        switch (status) {
            case 400:
                if (typeof data === "string") {
                    notify(data, 'error', 2000)
                }
                if (config.method === "get" && data.errors.hasOwnProperty("id")) {
                    history.push("/not-found")
                }
                if (data.errors) {
                    const modalStateErrors = []
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modalStateErrors.push(data.errors[key])
                        }
                    }
                    throw modalStateErrors.flat()
                }
                break
            case 401:
                if (
                    status === 401 &&
                    headers["www-authenticate"]?.startsWith(
                        'Bearer error="invalid_token"'
                    )
                ) {
                    store.userStore.logoutUser()
                    notify('Session expired - please login again', 'error', 2000)
                }

                break
            case 404:
                history.push("/not-found")
                break
            case 500:
                const errors = data as ServerError
                if (errors?.Errors) {
                    store.commonStore.setServerError(errors)
                    history.push("/server-error")
                } else {
                    notify(errors?.Message, 'error', 2000)
                }
                break
        }
        return Promise.reject(error)
    }
)

const responseBody = <T>(response: AxiosResponse<T>) => response.data

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody)
}

const AccountEndpoints = {
    current: () => requests.get<any>("/v1/users"),
    register: (user: UserFormPlanSelection) => requests.post<any>("/v1/users/signup", user),
    resendEmailConfirm: () => requests.post<any>(`/v1/users/resend-verification-email`, {}),
    update: (user: UserModel) => requests.post<any>("/v1/users/profile", user),
    get: (id: string) => requests.get<any>(`/v1/accounts/${id}`),
    getTeams: (accountId: string) => requests.get<any>(`/v1/accounts/${accountId}/teams`),
    getTeam: (accountId: string, teamId: string) => requests.get<any>(`/v1/accounts/${accountId}/teams/${teamId}`)
}

const OrganisationEndpoints = {
    get: (id: string) => requests.get<any>(`/v1/organisations/${id}`),
    create: (organisation: any) => requests.post<any>("/v1/organisations", organisation),
    update: (organisation: any) => requests.put<any>(`/v1/organisations/${organisation.id}`, organisation),
    getVats: (organisationId: string) => requests.get<any>(`/v1/organisations/${organisationId}/vats`),
    createVat: (organisationId: string, vat: any) => requests.post<any>(`/v1/organisations/${organisationId}/vats`, vat),
    updateVat: (organisationId: string, vatId: string, vat: VATMpet) => requests.put<any>(`/v1/organisations/${organisationId}/vats/${vatId}`, vat),
    deleteVat: (organisationId: string, vatId: string) => requests.del<any>(`/v1/organisations/${organisationId}/vats/${vatId}`),
    getNominals: (organisationId: string) => requests.get<any>(`/v1/organisations/${organisationId}/nominals`),
    createNominal: (organisationId: string, nominal: NominalCodeMpet) => requests.post<any>(`/v1/organisations/${organisationId}/nominals`, nominal),
    updateNominal: (organisationId: string, nominalId: string, nominal: NominalCodeMpet) => requests.put<any>(`/v1/organisations/${organisationId}/nominals/${nominalId}`, nominal),
    deleteNominal: (organisationId: string, nominalId: string) => requests.del<any>(`/v1/organisations/${organisationId}/nominals/${nominalId}`),
}

const AddressEndpoints = {
    getAll: () => requests.get<any>('/v1/addresses'),
    get: (id: string) => requests.get<any>(`/v1/addresses/${id}`),
    create: (organisationId: string, address: Address) => requests.post<any>(`/v1/addresses?organisationId=${organisationId}`, address),
    update: (organisationId: string, address: Address) => requests.put<any>(`/v1/addresses/${address.id}?organisationId=${organisationId}`, address),
    delete: (organisationId: string, addressId: string) => requests.del<any>(`/v1/addresses/${addressId}?organisationId=${organisationId}`),
}

const CustomerEndpoints = {
    getAll: () => requests.get<any>('/v1/customers'),
    get: (id: string) => requests.get<any>(`/v1/customers/${id}`),
    create: (organisationId: string, customer: Customer) => requests.post<any>(`/v1/customers?organisationId=${organisationId}`, customer),
    update: (organisationId: string, customer: Customer) => requests.put<any>(`/v1/customers/${customer.id}?organisationId=${organisationId}`, customer),
    delete: (organisationId: string, customerId: string) => requests.del<any>(`/v1/customers/${customerId}?organisationId=${organisationId}`),
}

const SupplierEndpoints = {
    getAll: () => requests.get<any>('/v1/suppliers'),
    get: (id: string) => requests.get<any>(`/v1/suppliers/${id}`),
    create: (organisationId: string, supplier: Supplier) => requests.post<any>(`/v1/suppliers?organisationId=${organisationId}`, supplier),
    update: (organisationId: string, supplier: Supplier) => requests.put<any>(`/v1/suppliers/${supplier.id}?organisationId=${organisationId}`, supplier),
    delete: (organisationId: string, supplierId: string) => requests.del<any>(`/v1/suppliers/${supplierId}?organisationId=${organisationId}`),
}

const BucketEndpoints = {
    getAll: () => requests.get<any>(`${bucketBaseURL}/v1/buckets`),
    get: (bucketId: string) => requests.get<any>(`${bucketBaseURL}/v1/buckets/${bucketId}`),
    create: (organisationId: string, bucket: IBucket) => requests.post<any>(`${bucketBaseURL}/v1/buckets?organisationId=${organisationId}`, bucket),
    update: (organisationId: string, bucket: IBucket) => requests.put<any>(`${bucketBaseURL}/v1/buckets/${bucket.id}?organisationId=${organisationId}`, bucket),
    delete: (organisationId: string, bucketId: string) => requests.del<any>(`${bucketBaseURL}/v1/buckets/${bucketId}?organisationId=${organisationId}`),
}

const DeclarationClearenceEndpoints = {
    //getAll: (bucketId: string) => requests.get<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences`),
    get: (bucketId: string, clearanceId: string) => requests.get<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences/${clearanceId}`),
    create: (bucketId: string, declaration: DeclarationClearence) => requests.post<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences/declarations`, declaration),
    update: (bucketId: string, clearanceId: string, declaration: DeclarationClearence) => requests.put<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences/${clearanceId}/declarations`, declaration),
    delete: (bucketId: string, clearanceId: string) => requests.del<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences/${clearanceId}`),
}

const PortClearenceEndpoints = {
    //getAll: (bucketId: string) => requests.get<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences`),
    get: (bucketId: string, clearanceId: string) => requests.get<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences/${clearanceId}`),
    create: (bucketId: string, port: PortClearence) => requests.post<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences/ports`, port),
    update: (bucketId: string, clearanceId: string, port: PortClearence) => requests.put<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences/${clearanceId}/ports`, port),
    delete: (bucketId: string, clearanceId: string) => requests.del<any>(`${bucketBaseURL}/v1/buckets/${bucketId}/clearences/${clearanceId}`),
}

const AliveEndpoints = {
    initManagement: () => requests.get<any>('/v1/users/init'),
    initBucket: () => requests.get<any>(`${bucketBaseURL}/v1/buckets/init`),
}

const agent = {
    Account: AccountEndpoints,
    Organisation: OrganisationEndpoints,
    Address: AddressEndpoints,
    Customer: CustomerEndpoints,
    Supplier: SupplierEndpoints,
    Bucket: BucketEndpoints,
    DeclarationClearence: DeclarationClearenceEndpoints,
    PortClearence: PortClearenceEndpoints,
    Alive: AliveEndpoints
}

export default agent
