import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react-lite"
import { ClearenceFromValues, ClearenceType } from '../../../app/models/clearences/clearences'
import { Button, Form, ScrollView } from 'devextreme-react'
import { GroupItem, Item, Label } from 'devextreme-react/form'
import TeamsTagBox from '../../../app/layout/teamsTagBox/TeamsTagBox'
import { customsRoutesOptions } from '../../../app/common/options/customsRoutesOptions'
import { formatDateToDisplay } from '../../../app/common/utils/formatDate'
import { ClearenceAuthorityType, PortClearence, PortClearenceAuthority } from '../../../app/models/clearences/portClearence'
import PortDeclarationDetails from './port/PortDeclarationDetails'
import { useStore } from '../../../app/stores/store'

interface Props {
    bucketId: string
    closePopUp: () => void
}

export default observer(function BucketFormItem({ bucketId, closePopUp }: Props) {
    const { bucketStore, portClearenceStore } = useStore()
    const [bucketClearence, setBucketClearence] = useState<ClearenceFromValues>(
        new ClearenceFromValues(bucketStore.clearance)
    )

    useEffect(() => {
        if (bucketStore.clearance?.transactionId) {
            // Get the data from the store...
            if (bucketClearence.entry?.mrNs) bucketStore.addMrnArray(bucketClearence.entry?.mrNs, true)
            if (bucketClearence.exit?.mrNs) bucketStore.addMrnArray(bucketClearence.exit?.mrNs, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bucketStore.clearance])

    const colCountByScreen = {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2
    }

    function onTeamsChanged(teamIds: string[]) {
        setBucketClearence({ ...bucketClearence, teams: teamIds })
    }

    const handleRouteChanged = (e: any) => {
        const selectedRoute = e.value
        const currentRoute = customsRoutesOptions.find(x => x.description === selectedRoute)

        setBucketClearence({
            ...bucketClearence,
            route: selectedRoute,
            exit: new PortClearenceAuthority(currentRoute?.portExitAuth === "PBN" ? ClearenceAuthorityType.PBN : ClearenceAuthorityType.GMR),
            entry: new PortClearenceAuthority(currentRoute?.portEntryAuth === "PBN" ? ClearenceAuthorityType.PBN : ClearenceAuthorityType.GMR)
        })
    }

    function handleFormClose() {
        closePopUp()
    }

    function handleSaveButton(e: any) {
        var validation = e?.validationGroup?.validate()
        if (validation?.isValid) {
            // save the clearance....            
            let updatedBuckey = {
                ...bucketClearence,
                exit: { ...bucketClearence.exit!, mrNs: bucketStore.exitMrns },
                entry: { ...bucketClearence.entry!, mrNs: bucketStore.entryMrns }
            }
            if (!bucketClearence.transactionId) {
                portClearenceStore.createPortClearence(bucketId, updatedBuckey).then(clearenceResponse => {
                    bucketStore.addClearence(clearenceResponse)
                    closePopUp()
                })
            } else {
                portClearenceStore.updatePortClearence(bucketId, updatedBuckey).then(clearenceResponse => {
                    bucketStore.updateClearence(clearenceResponse)
                    closePopUp()
                })
            }
        }
    }

    return (
        <ScrollView width='100%' height='100%'>
            <div className={'dx-card responsive-paddings'}>
                <Form
                    id={'clearance-form'}
                    formData={bucketClearence}
                    labelLocation={'top'}
                    colCountByScreen={colCountByScreen}
                    showValidationSummary={true}
                    validationGroup={'clearanceFormValidation'}
                >
                    <GroupItem caption={'Route & Teams'} >
                        <Item dataField="route"
                            editorType="dxSelectBox"
                            text={"Route"}
                            editorOptions={{
                                value: bucketClearence?.route,
                                items: customsRoutesOptions,
                                searchEnabled: true,
                                displayExpr: "description",
                                valueExpr: "description",
                                placeholder: "Select a route...",
                                onValueChanged: handleRouteChanged,
                                disabled: bucketClearence?.id ? true : false
                            }}>
                            <Label text="Route" />
                        </Item>
                        <Item caption={"Teams"}>
                            <Label text="Teams" />
                            <TeamsTagBox
                                teamIds={bucketClearence?.teams}
                                onTeamsChanged={onTeamsChanged}
                            />
                        </Item>
                    </GroupItem>
                    <GroupItem caption={'Info'} >
                        <p>{`Requested by: ${bucketClearence?.requestedBy ?? 'Pending'}`}  </p>
                        <p>{`Requested at: ${bucketClearence?.requested ? formatDateToDisplay(bucketClearence?.requested) : 'Pending'}`} </p>
                    </GroupItem>

                    <GroupItem colSpan={2}  >
                        {bucketClearence.type === ClearenceType.Port &&
                            <PortDeclarationDetails
                                portClearence={bucketClearence as PortClearence}
                            />
                        }
                    </GroupItem>

                    <GroupItem >
                        <Item>
                            <div className='default-dropdown-button-table default-dropdown-button-w-100'>
                                <div>
                                    <Button
                                        icon="check"
                                        type="success"
                                        stylingMode="contained"
                                        validationGroup='clearanceFormValidation'
                                        onClick={(e) => handleSaveButton(e)}
                                    />{' '}
                                    <Button
                                        icon="close"
                                        type="danger"
                                        stylingMode="contained"
                                        onClick={() => handleFormClose()}
                                    />
                                </div>
                            </div>
                        </Item>
                    </GroupItem>
                </Form>
            </div>
        </ScrollView>
    )
})