import agent from "../api/agent"
import { Customer, CustomerResponse } from '../models/customer'
import { makeAutoObservable, runInAction } from "mobx"

export default class CustomerStore {
    customerList: Customer[] | undefined = undefined
    customer: Customer | undefined = undefined
    loading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingCustomer = (state: boolean) => {
        this.loading = state
    }

    getCustomers = async () => {
        try {
            this.setLoadingCustomer(true)

            const response = await agent.Customer.getAll()

            if (response.data) {
                const tempArray: Customer[] = []
                response.data.map((data: CustomerResponse) => (
                    tempArray.push(this.createCustomerObj(data))
                ))

                runInAction(() => {
                    this.customerList = tempArray
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingCustomer(false)
            })
        }
    }

    getCustomer = async (id: string) => {
        try {
            this.setLoadingCustomer(true)

            console.log("getCustomer", id)

            const response = await agent.Customer.get(id)

            if (response.data)
                return this.createCustomerObj(response.data)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingCustomer(false)
            })
        }
    }

    updateCustomer = async (organisationId: string, customer: Customer) => {
        try {
            this.setLoadingCustomer(true)

            console.log("updateCustomer", customer)

            const response = await agent.Customer.update(organisationId, customer)
            console.log(response)
            if (response.data) {

                const tempArray = this.customerList?.map(a => a.id === customer.id ? customer : a)
                runInAction(() => {
                    this.customerList = tempArray
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingCustomer(false)
            })
        }
    }

    createCustomer = async (organisationId: string, customer: Customer) => {
        try {
            this.setLoadingCustomer(true)

            console.log("createCustomer", JSON.stringify(customer))

            const response = await agent.Customer.create(organisationId, customer)
            console.log(response)
            if (response.data) {
                customer.id = response.data.id

                runInAction(() => {
                    this.customerList?.push(customer)
                })
            }
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingCustomer(false)
            })
        }
    }

    deleteCustomer = async (orgId: string, id: string) => {
        try {
            this.setLoadingCustomer(true)

            await agent.Customer.delete(orgId, id)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingCustomer(false)
            })
        }
    }

    private createCustomerObj(data: CustomerResponse): Customer {
        let customer: Customer = {
            code: data.code,
            name: data.name,
            eori: data.eori,
            contact: data.contact,
            currencyCode: data.currency?.code,
            tax: data.tax,
            address: data.address,
            billingAddress: data.billingAddress,
            tags: data.tags,
            id: data.id,
            status: data.status,
        }

        if (customer.tax?.tax) {
            customer.tax!.taxId = customer.tax.tax.id
        }

        if (data.organisation) {
            customer.organisationId = data.organisation.id
        }

        if (data.address.country) {
            customer.address.countryISOCode = data.address.country.isoCode
        }

        if (data.billingAddress.country) {
            customer.billingAddress.countryISOCode = data.billingAddress.country.isoCode
        }

        return customer
    }
}