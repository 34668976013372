import React, { useEffect, useRef, useCallback, useMemo } from 'react'
import TreeView from 'devextreme-react/tree-view'
import { useScreenSize } from '../../common/utils/mediaQuery'
import './sideNavigationMenu.css'
import { useNavigation } from '../../context/NavigationContext'
import { navigation } from '../../routes/appNavigation'

import * as events from 'devextreme/events'

interface Props {
    children: any,
    selectedItemChanged: any,
    openMenu: any,
    compactMode: any,
    onMenuReady: any,
}

export default function SideNavigationMenu({ children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady }: Props) {

    const { isLarge } = useScreenSize()

    function normalizePath() {
        return navigation.map((item) => {
            if (item.path && !(/^\//.test(item.path))) {
                item.path = `/${item.path}`
            }
            return { ...item, expanded: isLarge }
        })
    }

    const items = useMemo(
        normalizePath,
        []
    )

    const { navigationData: { currentPath } } = useNavigation()

    const treeViewRef = useRef<TreeView>(null)
    const wrapperRef = useRef()
    const getWrapperRef = useCallback((element) => {
        const prevElement = wrapperRef.current
        if (prevElement) {
            events.off(prevElement, 'dxclick')
        }

        wrapperRef.current = element
        events.on(element, 'dxclick', (e: any) => {
            openMenu(e)
        })
    }, [openMenu])

    useEffect(() => {

        const treeView = treeViewRef.current && treeViewRef.current.instance
        if (!treeView) {
            return
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath)
            treeView.expandItem(currentPath)
        }

        if (compactMode) {
            treeView.collapseAll()
        }

    }, [currentPath, compactMode])


    return (
        <div
            className={'dx-swatch-additional side-navigation-menu'}
            ref={getWrapperRef}
        >
            {children}
            <div className={'menu-container'}>
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr={'path'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'click'}
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width={'100%'}
                />
            </div>
        </div>
    )
}
