import { VATMpet } from "./vat"
import { NominalCodeMpet } from './nominalCode'
import { Contact } from './contact'
import { Currency } from './currency'
import { Tag } from "./tag"

export interface Organisation {
  id?: string,
  accountId?: string,
  name: string,
  logo?: string,
  operationalTimeZone: string,
  status: number,
  address: OrganisationAddress,
  contact: Contact,
  defaultCurrency: Currency,
  taxIdentifierLabel1?: string,
  taxIdentifierLabel2?: string,
  listSettings: OrganisationListSetting,
  settings: OrganisationSettings,
  tags?: Tag[],
  bucketCounterPrefix?: string,
  bucketCounterNumber?: number,
  bucketCounterIsUsed?: boolean,
  clearenceCounterPrefix?: string,
  clearenceCounterNumber?: number,
  clearenceCounterIsUsed?: boolean
}


export class OrganisationFormValues {
  id?: string = undefined
  name: string = ""
  logo?: string | undefined = undefined
  operationalTimeZone: string = "Dublin"
  status: number = 1
  address: OrganisationAddress = {
    code: "",
    address1: "",
    address2: "",
    city: "",
    postcode: "",
    countryISOCode: "IE",
  }
  contact: Contact = {
    name: "",
    phoneNumber: "",
    email: "",
    web: ""
  }
  defaultCurrency: Currency = {
    code: "EUR",
    name: "",
    symbol: "",
    default: true
  }
  taxIdentifierLabel1?: string = undefined
  taxIdentifierLabel2?: string = undefined
  bucketCounterPrefix?: string = "BUC"
  bucketCounterNumber?: number = 10
  bucketCounterIsUsed?: boolean = false
  clearenceCounterPrefix?: string = "CLR"
  clearenceCounterNumber?: number = 10
  clearenceCounterIsUsed?: boolean = false

  constructor(organisation?: OrganisationFormValues) {
    if (organisation) {
      this.id = organisation.id
      this.name = organisation.name
      this.logo = organisation.logo
      this.operationalTimeZone = organisation.operationalTimeZone
      this.status = organisation.status
      this.address = organisation.address
      this.contact = organisation.contact
      this.defaultCurrency = organisation.defaultCurrency
      this.operationalTimeZone = organisation.operationalTimeZone
      this.taxIdentifierLabel1 = organisation.taxIdentifierLabel1
      this.taxIdentifierLabel2 = organisation.taxIdentifierLabel2
      this.bucketCounterPrefix = organisation.bucketCounterPrefix
      this.bucketCounterNumber = organisation.bucketCounterNumber
      this.bucketCounterIsUsed = organisation.bucketCounterIsUsed
      this.clearenceCounterPrefix = organisation.clearenceCounterPrefix
      this.clearenceCounterNumber = organisation.clearenceCounterNumber
      this.clearenceCounterIsUsed = organisation.clearenceCounterIsUsed
    }
  }
}

export interface OrganisationAddress {
  code: string,
  address1: string,
  address2: string,
  city: string,
  postcode: string,
  countryISOCode: string
}

export interface OrganisationSettings {
  useCustomAccountCode?: boolean,
  taxIdentifierLabel1?: string,
  taxIdentifierLabel2?: string,
  transactions?: OrganisationCounters[]
}

export interface OrganisationListSetting {
  vatRates?: VATMpet[],
  nominalCodes?: NominalCodeMpet[]
}

export interface OrganisationCounters {
  type: number,
  prefix: string,
  currentValue: number,
  isUsed: boolean
}