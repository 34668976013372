import { DataGrid, LoadPanel } from 'devextreme-react'
import { Column, Editing, Lookup, Paging } from 'devextreme-react/data-grid'
import React, { useEffect } from 'react'
import { observer } from "mobx-react-lite"
import { useStore } from "../../../app/stores/store"
import ActionArea from '../../../app/layout/actionArea/ActionArea'
import { ActionButton } from '../../../app/models/actionButton'
import notify from 'devextreme/ui/notify'
import { EditingStartEvent } from 'devextreme/ui/data_grid'
import { IBucket, TransactionId } from '../../../app/models/bucket'
import { useHistory } from "react-router-dom"

const BucketPage: React.FC = () => {
    const history = useHistory()
    const {
        bucketStore: { loadingBucket, bucketList, getBuckets, deleteBucket },
        customerStore
    } = useStore()

    useEffect(() => {
        if (customerStore.customerList === undefined || customerStore.customerList.length === 0)
            customerStore.getCustomers()
        getBuckets()
    }, [getBuckets, customerStore])

    if (loadingBucket || customerStore.loading) {
        return <LoadPanel visible={true} />
    }

    const buttons: ActionButton[] = [
        {
            icon: 'add',
            type: 'success',
            text: '',
            onClick: () => history.push('/create-custom-bucket')
        }
    ]

    const handleRowRemoved = (row: any) => {
        deleteBucket(row.data.organisation.id, row.data.id).then(() => {
            notify(`Bucket deleted: ${row.data.id}`, 'success')
        })
    }

    const handleEditingStarting = (e: EditingStartEvent<IBucket, any>) => {
        e.cancel = true

        notify(`Bucket to edit: ${e.data.id}`, 'success')
        history.push(`/edit-custom-bucket/${e.data.id}`)
    }

    const formatTransactionId = (options: any): string => {
        let transactionId = options.value as TransactionId
        return `${transactionId.prefix}-${transactionId.value}`
    }

    const formatOrganisationId = (options: any): string => {
        let organisation = options.value as { id: string, name: string }
        return `${organisation.name}`
    }

    return (
        <div className="content-block">
            <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
                <h4 style={{ margin: '15px 5px 15px 30px' }}>Buckets</h4>
                <ActionArea buttons={buttons} />
            </div>

            <div className="content-block">
                <div className={'dx-card'}>
                    <DataGrid
                        dataSource={bucketList}
                        keyExpr="id"
                        showBorders={true}
                        onRowRemoved={handleRowRemoved}
                        onEditingStart={e => handleEditingStarting(e)}
                    >

                        <Paging enabled={true} />
                        <Editing
                            allowUpdating={true}
                            allowAdding={false}
                            allowDeleting={true}
                            mode="row" />
                        <Column dataField="transactionId"
                            customizeText={formatTransactionId} />
                        <Column dataField="customerId">
                            <Lookup
                                dataSource={customerStore.customerList}
                                valueExpr="id"
                                displayExpr="name"
                            />
                        </Column>
                        <Column dataField="organisation" customizeText={formatOrganisationId} />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default observer(BucketPage)
