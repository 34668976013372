import React from 'react'
import ReactDOM from 'react-dom'
import "./app/themes/theme.base.css"
import "./app/themes/theme.additional.css"
import "./app/layout/styles.css"
import App from './app/layout/App'
import reportWebVitals from './reportWebVitals'
import { Router } from "react-router-dom"
import { store, StoreContext } from "./app/stores/store"
import { createBrowserHistory } from 'history'
import ScrollToTop from "./app/layout/ScrollToTop"
import { AuthProvider } from './app/context/AuthContext'
import { NavigationProvider } from './app/context/NavigationContext'


export const history = createBrowserHistory()


ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <Router history={history}>
        <AuthProvider>
          <NavigationProvider>
            <ScrollToTop />
            <App />
          </NavigationProvider>
        </AuthProvider>
      </Router>
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
