import React from 'react'
import Button from 'devextreme-react/button'
import { ActionButton } from '../../models/actionButton'
import './actionArea.css'

interface Props {
    buttons: ActionButton[]
}

export default function ActionArea({ buttons }: Props) {
    return (
        <div className='action-area'>
            {buttons.map((button, index) => (<Button
                key={index}
                className='action-button'
                type={button.type ? button.type : 'normal'}
                icon={button.icon ? button.icon : ''}
                text={button.text ? button.text : ''}
                onClick={button.onClick}
            />
            ))}

        </div>
    )
}