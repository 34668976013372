import React from 'react'
import './planCard.css'
import { Button } from 'devextreme-react/button'
import { UserPlan } from '../../../../app/models/user'

interface PlanCardProps {
    title: string,
    description: string,
    price: number,
    onSelected: Function
}

const PlanCard: React.FC<PlanCardProps> = (prop) => {

    function planSelected() {
        let plan: UserPlan = {
            id: '$id-' + prop.title,
            name: prop.title,
            price: prop.price
        }

        prop.onSelected(plan)
    }

    return (
        <div className={'single-card dx-card content plan-card'} style={{margin: 20, maxWidth: 450, minWidth: 280}}>
            <div className={'header'}>
                <div className={'title'}>{prop.title}</div>

            </div>
            <div className={'description'}>{prop.description}</div>
            <h4>€ {prop.price}</h4>
            <Button className={'dx-button'} type="default"
                  stylingMode="contained" width={120} text="Buy now" onClick={() => planSelected()}></Button>
        </div>
    )
}

export default PlanCard