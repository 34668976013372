import React, { useCallback, useMemo } from 'react'
import ContextMenu, { Position } from 'devextreme-react/context-menu'
import List from 'devextreme-react/list'
import { useAuth0 } from '@auth0/auth0-react'
import './userPanel.css'
import { useStore } from "../../stores/store"
import { history } from '../../..'

interface Props {
    menuMode: 'context' | 'list'
}

export default function UserPanel({ menuMode }: Props) {
    const { logout } = useAuth0()
    const { userStore, commonStore } = useStore()


    const logUserOut = useCallback(() => {
        commonStore.setToken(null)
        logout({ returnTo: window.location.origin })
    }, [commonStore, logout])

    function navigateToProfile() {
        history.push("/profile")
    }

    const menuItems = useMemo(() => ([
        {
            text: 'Profile',
            icon: 'user',
            onClick: navigateToProfile
        },
        {
            text: 'Logout',
            icon: 'runner',
            onClick: logUserOut
        }
    ]), [logUserOut])


    return (
        <div className={'user-panel'}>
            <div className={'user-info'}>
                <div className={'image-container'}>
                    <div
                        style={{
                            background: `url(${userStore.user?.picture}) no-repeat #fff`,
                            backgroundSize: 'cover'
                        }}
                        className={'user-image'} />
                </div>
                <div className={'user-name'}>{userStore.user?.nickName}</div>
            </div>

            {menuMode === 'context' && (
                <ContextMenu
                    items={menuItems}
                    target={'.user-button'}
                    showEvent={'dxclick'}
                    width={210}
                    cssClass={'user-menu'}
                >
                    <Position my={'top center'} at={'bottom center'} />
                </ContextMenu>
            )}
            {menuMode === 'list' && (
                <List className={'dx-toolbar-menu-action'} items={menuItems} />
            )}
        </div>
    )
}
