import { makeAutoObservable, runInAction } from "mobx"
import { Notification } from '../models/notification'

export default class NotificationStore {
    notificationRegistry = new Map<string, Notification>()
    selectedNotification: Notification | undefined = undefined
    loading = false


    constructor() {
        makeAutoObservable(this)
    }


    addNotification = (notification: Notification) => {
        this.setLoading(true)
        try {
            this.notificationRegistry.set(notification.id, notification)
            runInAction(() => {
                this.selectedNotification = notification
                this.setLoading(false)
            })
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.setLoading(false)
            })
            throw error
        }
    }

    removeNotification = (id: string) => {
        this.setLoading(true)
        try {
            runInAction(() => {
                this.notificationRegistry.delete(id)
                this.setLoading(false)
            })
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.setLoading(false)
            })
            throw error
        }
    }

    removeAllNotification = () => {
        this.setLoading(true)
        try {
            runInAction(() => {
                this.notificationRegistry.clear()
                this.setLoading(false)
            })
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.setLoading(false)
            })

        }
    }

    setLoading = (value: boolean) => {
        this.loading = value
    }
}