import React, { useEffect } from "react"
import './profilePage.css'
import Form, { ButtonItem, GroupItem, Item, Label } from 'devextreme-react/form'
import { useStore } from "../../../app/stores/store"
import { LoadPanel } from "devextreme-react"
import notify from "devextreme/ui/notify"
import { observer } from 'mobx-react-lite'
import { languageOptions } from '../../../app/common/options/languageOptions'
import { datePatternOptions } from '../../../app/common/options/datePatternOptions'
import { timePatternOptions } from '../../../app/common/options/timePatternOptions'
import { timeZoneOptions } from '../../../app/common/options/timeZoneOptions'
import { alert } from "devextreme/ui/dialog"
import { history } from '../../..'

export default observer(function ProfilePage() {

    const { userStore: { user, update } } = useStore()

    useEffect(() => {
        if (user?.status === 'unverified')
            alert('Please complete your profile', 'Profile')
    }, [user?.status])

    if (!user) {
        return <LoadPanel visible={true} />
    }

    function handleFormSubmit() {
        update(user!).then((redirect) => {
            notify('User info updated', 'success')
            if (redirect) {
                history.push('/organisation')
            }
        })
    }

    const buttonOptions = {
        id: 'mybutton',
        icon: 'check',
        type: 'success',
        useSubmitBehavior: false,
        onClick: function (e: any) {
            var validation = e?.validationGroup?.validate()
            if (validation?.isValid) {
                handleFormSubmit()
            }
        }
    }

    const onInitialized = () => {
        var elements = document.getElementsByName("nickName")
        elements[0]?.focus()
    }

    return (
        <div className='content-block'>
            <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
                <div className={'form-avatar'} style={{ margin: '5px 0px 5px 5px' }}>
                    <img
                        alt={user?.fullName}
                        src={user?.picture}
                        style={{ width: 70, height: 70, marginTop: 20, marginLeft: 20 }}
                    />
                </div>
                <h4 style={{ margin: '15px 5px 15px 30px' }}>{user?.fullName}</h4>
            </div>

            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={user}
                    labelLocation={'top'}
                    colCountByScreen={colCountByScreen}
                    showValidationSummary={true}
                    onContentReady={onInitialized}
                >
                    <GroupItem caption="User Details">
                        <Item dataField="nickName" ><Label text="Display Name" /></Item>
                        <Item dataField="firstName" isRequired={true} />
                        <Item dataField="lastName" isRequired={true} />
                    </GroupItem>
                    <GroupItem caption="Locale">
                        <Item dataField="userMetadata.language"
                            editorType="dxSelectBox"
                            text={"Language"}
                            editorOptions={{
                                value: user.userMetadata?.language,
                                items: languageOptions,
                                searchEnabled: true,
                                displayExpr: "name",
                                valueExpr: "code",
                                placeholder: "Select a language..."
                            }}>
                            <Label text="Language" />
                        </Item>
                        <Item dataField="userMetadata.datePattern"
                            editorType="dxSelectBox"
                            text={"Date Pattern"}
                            editorOptions={{
                                value: user.userMetadata?.datePattern,
                                items: datePatternOptions,
                                searchEnabled: true,
                                displayExpr: "pattern",
                                valueExpr: "pattern",
                                placeholder: "Select a pattern..."
                            }}>
                            <Label text="Date Pattern" />
                        </Item>
                        <Item dataField="userMetadata.timePattern"
                            editorType="dxSelectBox"
                            text={"Time Pattern"}
                            editorOptions={{
                                value: user.userMetadata?.timePattern,
                                items: timePatternOptions,
                                searchEnabled: true,
                                displayExpr: "pattern",
                                valueExpr: "pattern",
                                placeholder: "Select a time pattern..."
                            }}>
                            <Label text="Time Pattern" />
                        </Item>
                        <Item dataField="userMetadata.timeZone"
                            editorType="dxSelectBox"
                            text={"Time Zone"}
                            editorOptions={{
                                value: user.userMetadata?.timeZone,
                                items: timeZoneOptions,
                                searchEnabled: true,
                                displayExpr: "display",
                                valueExpr: "value",
                                placeholder: "Select a time zone..."
                            }}>
                            <Label text="Time Zone" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="">

                    </GroupItem>
                    <GroupItem caption="">

                    </GroupItem>
                    <ButtonItem
                        horizontalAlignment="left"
                        verticalAlignment="bottom"
                        buttonOptions={buttonOptions}
                    />
                </Form>
            </div>
        </div>
    )
})

const colCountByScreen = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4
}