import { Tag } from "./tag";

export const AddressStatus = [
    { value: -1, description: 'Inactive' },
	{ value: 1, description: 'Active' },
]

export const AddressTypes = [
    { value: 1, description: 'Billing Address' },
    { value: 2, description: 'Service Address' },
    { value: 3, description: 'Port or Terminal' }
]

export const GatesTypes = [
    { value: 1, description: 'Gate' },
    { value: 2, description: 'Bay' },
    { value: 3, description: 'Loading location' },
    { value: 4, description: 'Offloading location' },
    { value: 5, description: 'Waiting area' },
    { value: 6, description: 'Tank' },    
]

export interface Address {    
    organisationId?: string,
    id?: string,
    personId?: string,
    type: number,
    eori?: string,
    code: string,
    name: string,
    address1: string,
    address2: string,
    city: string,
    region: string,
    postcode: string,
    countryISOCode: string,
    status: number,
    notes?: string,
    lat?: number,
    lon?: number,
    flagGatesAndAreas?: boolean,
    areas?: GatesAndAreas[],
    //sideNotes?: string
    lastUsed?: Date,
    tags?: Tag[]   
    
}

export interface Person { 
    id: string,
    name: string,
    type: number
}

export interface AddressResponse extends Address {
    country: {
        isoCode: string,
        name: string
    },
    organisation?: {
        id: string,
        name: string
    },
    person?: Person 
}

export interface GatesAndAreas {    
    name: string,
    type: string,
    notes: string,
    lat: number,
    lon: number,
}