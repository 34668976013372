import React, { useEffect } from 'react'
import { observer } from "mobx-react-lite"
import { Switch, Route, Redirect } from 'react-router-dom'
import SideNavBarLayout from '../layout/sideNavBarLayout/SideNavInnerToolbar'
import Footer from '../layout/footer/Footer'
import { useStore } from '../stores/store'
import { LoadPanel } from 'devextreme-react'
import routes from '../routes/appRoutes'
import ProfilePage from '../../features/profile/profilePage/ProfilePage'
import OrganisationPage from '../../features/organisation/organisationPage/OrganisationPage'
import VerifyAccount from '../layout/verifyAccount/VerifyAccount'

export default observer(function Content() {
    const { userStore, accountStore } = useStore()

    useEffect(() => {
        if (userStore.user?.appMetadata?.loggedIn?.accId) {
            accountStore.getTeams(userStore.user.appMetadata.loggedIn.accId)
        }
    }, [userStore.user, accountStore])

    if (!userStore.user)
        return <LoadPanel visible={true} />

    if (!userStore.user.emailVerified)
        return <VerifyAccount />

    return (
        <SideNavBarLayout title="mycustomclouds">
            {(userStore.user.status === 'unverified') && (
                <Switch>
                    <Route exact path='/profile' component={ProfilePage} />
                    <Redirect to={'/profile'} />
                </Switch>
            )}

            {(userStore.user.status !== 'unverified' && (userStore.user.appMetadata?.organisations === undefined ||
                userStore.user.appMetadata?.organisations?.length === 0)) && (
                    <Switch>
                        <Route exact path='/profile' component={ProfilePage} />
                        <Route exact path='/organisation' component={OrganisationPage} />
                        <Redirect to={'/organisation'} />
                    </Switch>
                )}

            {(userStore.user.appMetadata?.organisations !== undefined && userStore.user.appMetadata?.organisations?.length > 0) && (
                <Switch>
                    {routes.map(({ path, component }) => (
                        <Route
                            exact
                            key={path}
                            path={path}
                            component={component}
                        />
                    ))}
                    <Redirect to={'/dashboard'} />
                </Switch>
            )}
            <Footer>
                Copyright © 2011-{new Date().getFullYear()} mycustomclouds.com
                <br />
                All trademarks or registered trademarks are property of their
                respective owners.
            </Footer>
        </SideNavBarLayout>
    )
})