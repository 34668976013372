import React from 'react'
import Form, { GroupItem, Item, Label } from 'devextreme-react/form'
import { PortClearence } from '../../../../app/models/clearences/portClearence'
import MRNsDetails from '../shared/MRNsDetails'


interface Props {
    isEntry: Boolean
    portClearence: PortClearence
}

export default function PBNDetails({ portClearence, isEntry }: Props) {

    const colCountByScreen = {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2
    }

    return (
        <Form formData={portClearence} colCountByScreen={colCountByScreen}>
            <GroupItem caption={isEntry ? "PBN - Entry" : "PBN - Exit"} >
                <Item dataField={(isEntry ? "entry" : "exit") + ".contact.name"}>
                    <Label text="Name" />
                </Item>
                <Item dataField={(isEntry ? "entry" : "exit") + ".contact.mobileNumber1"}>
                    <Label text="Mobile 1" />
                </Item>
                <Item dataField={(isEntry ? "entry" : "exit") + ".contact.mobileNumber2"}>
                    <Label text="Mobile 2" />
                </Item>
                <Item dataField={(isEntry ? "entry" : "exit") + ".contact.email"} >
                    <Label text="Email" />
                </Item>
            </GroupItem>
            <GroupItem caption={isEntry ? "MRNs - Entry" : "MRNs - Exit"}>
                <MRNsDetails isEntry={isEntry}  />
            </GroupItem>
        </Form>
    )
}