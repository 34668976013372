import React, { useEffect, useState } from "react"
import './organisationPage.css'
import Form, { Item, GroupItem, Label, ButtonItem } from 'devextreme-react/form'
import { useStore } from "../../../app/stores/store"
import { OrganisationFormValues } from "../../../app/models/organisation"
import { LoadPanel } from "devextreme-react"
import notify from "devextreme/ui/notify"
import { observer } from 'mobx-react-lite'
import { countryOptions } from '../../../app/common/options/countryOptions'
import { currencyOptions } from '../../../app/common/options/currencyOptions'
import { timeZoneOptions } from '../../../app/common/options/timeZoneOptions'
import TabPanel, { Item as TabItem } from "devextreme-react/tab-panel"
import VATsGrid from '../VATs/VATsGrid'
import NominalCodesGrid from '../nominalCodes/NominalCodesGrid'
import { alert } from "devextreme/ui/dialog"
import { useAuth0 } from '@auth0/auth0-react'
import { v4 as uuid } from "uuid"


export default observer(function OrganisationPage() {
  const { organisationStore, commonStore: { setToken }, userStore: { user } } = useStore()
  const { loadingOrganisation, getOrganisation, updateOrganisation, createOrganisation,
    setLoadingOrganisation } =
    organisationStore
  const { getAccessTokenSilently } = useAuth0()
  const [org, setOrg] = useState<OrganisationFormValues>(
    new OrganisationFormValues()
  )

  useEffect(() => {
    let isSubscribed = true

    if (user?.appMetadata?.organisations && user?.appMetadata?.organisations.length > 0) {
      const firstOrgId = user?.appMetadata?.organisations[0]?.id

      if (isSubscribed) {
        getOrganisation(firstOrgId).then(() => {
          setOrg(new OrganisationFormValues(organisationStore.organisation))
        })
      }
    }

    return () => {
      isSubscribed = false
    }
  }, [user, organisationStore, getOrganisation])


  useEffect(() => {
    const orgCount = user?.appMetadata?.organisations?.length
    if (orgCount === 0) {
      alert('Please create your 1st organisation', 'Organisation required')
    }
  }, [user?.appMetadata?.organisations?.length])


  function handleFormSubmit() {
    try {
      setLoadingOrganisation(true)

      if (!org.id) {
        createOrganisation(org).then(() => {
          getNewToken()
          notify('Organisation successfully created', 'success')
        }).catch((e) => {
          notify(`Organisation create error: ${e[0]}`, 'error')
        })
      } else {
        updateOrganisation(org).then(() => {
          getNewToken()
          notify('Organisation info updated', 'success')
        }).catch((e) => {
          notify(`Organisation update error: ${e[0]}`, 'error')
        })
      }

    } catch (error) {
      console.log(error)
    } finally {
      setLoadingOrganisation(false)
    }
  }



  function getNewToken() {
    const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE
    getAccessTokenSilently({ scope: uuid().toString(), audience: audience })
      .then((e) => {
        setToken(e)
      })
  }

  if (!user || loadingOrganisation) {
    return <LoadPanel visible={true} />
  }

  if (user && user.status === "unverified") {
    return (<div className='content-block'>
      <h2 className={'content-block'}>Organisation</h2>
      <p>Please complete the profile first</p>
    </div>
    )
  }

  const buttonOptions = {
    id: 'mybutton',
    icon: 'check',
    type: 'success',
    useSubmitBehavior: false,
    onClick: function (e: any) {
      var validation = e?.validationGroup?.validate()
      if (validation?.isValid) {
        handleFormSubmit()
      }
    }
  }

  const onTabChange = (e: any) => {
    if (e.itemData.title === 'Organisation') {
      var elements = document.getElementsByName("name")
      elements[0]?.focus()
    }
  }

  const onInitialized = () => {
    var elements = document.getElementsByName("name")
    elements[0]?.focus()
  }

  return (
    <div className='content-block'>
      <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
        <div className={'form-avatar'} style={{ margin: '5px 0px 5px 5px' }}>
          {org?.logo && (
            <img
              alt={org?.name}
              src={org.logo}
              style={{ width: 70, height: 70, marginLeft: 20 }}
            />)}
        </div>
        <h4 style={{ margin: '15px 5px 15px 30px' }}>{(org?.name) ? org?.name : 'Organisation'}</h4>
      </div>
      <div className={'content-block'}>
        <TabPanel onTitleClick={onTabChange}>
          <TabItem title="Organisation" icon="group">
            <div className={'dx-card-custom responsive-paddings'}>
              <Form
                id={'form'}
                formData={org}
                labelLocation={'top'}
                colCountByScreen={colCountByScreen}
                showValidationSummary={true}
                onContentReady={onInitialized}
              >
                <GroupItem caption="Info">
                  <Item dataField="name" editorOptions={{ value: org.name ? org.name : "" }} isRequired={true} />
                  <Item
                    dataField="operationalTimeZone"
                    editorType="dxSelectBox"
                    text={"Time Zone"}
                    editorOptions={{
                      value: org.operationalTimeZone ? org.operationalTimeZone : 'Dublin',
                      items: timeZoneOptions,
                      searchEnabled: true,
                      displayExpr: "display",
                      valueExpr: "value",
                      placeholder: "Select a time zone..."
                    }}>
                  </Item>

                  <Item
                    dataField="defaultCurrency.code"
                    editorType="dxSelectBox"
                    text={"Currency"}
                    editorOptions={{
                      value: org.defaultCurrency ? org.defaultCurrency.code : 'EUR',
                      items: currencyOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "code",
                      placeholder: "Select a currency..."
                    }}
                    isRequired={true}>
                    <Label text="Currency" />
                  </Item>

                  <Item dataField="taxIdentifierLabel1" caption={"Tax Label 1"}>
                    <Label text="Tax Label 1" />
                  </Item>

                  <Item dataField="taxIdentifierLabel2" caption={"Tax Label 2"}>
                    <Label text="Tax Label 2" />
                  </Item>
                </GroupItem>

                <GroupItem caption="Address"  >
                  <Item dataField="address.code" caption={"Code"} isRequired={true}>
                    <Label text="Code" />
                  </Item>
                  <Item dataField="address.address1" caption={"Address 1"} isRequired={true}>
                    <Label text="Address 1" />
                  </Item>
                  <Item dataField="address.address2" text={"Address 2"} >
                    <Label text="Address 2" />
                  </Item>
                  <Item dataField="address.postcode" text={"Postcode"} isRequired={true} >
                    <Label text="Postcode" />
                  </Item>
                  <Item dataField="address.countryISOCode"
                    editorType="dxSelectBox"
                    text={"Country"}
                    editorOptions={{
                      items: countryOptions,
                      searchEnabled: true,
                      displayExpr: "name",
                      valueExpr: "isoCode",
                      placeholder: "Select a country..."
                    }}
                    isRequired={true}>
                    <Label text="Country" />
                  </Item>
                </GroupItem>

                <GroupItem caption="Contact">
                  <Item dataField="contact.name" caption="Name">
                    <Label text="Name" />
                  </Item>
                  <Item dataField="contact.phoneNumber" caption="Phone"  >
                    <Label text="Phone" />
                  </Item>
                  <Item dataField="contact.email" caption="Email" >
                    <Label text="Email" />
                  </Item>
                  <Item dataField="contact.web" title="Web"  >
                    <Label text="Web" />
                  </Item>
                </GroupItem>

                <GroupItem caption="Counters">
                  <Item dataField="bucketCounterPrefix" caption="Bucket Prefix">
                    <Label text="Bucket Prefix" />
                  </Item>
                  <Item dataField="bucketCounterNumber" caption="Bucket Number" disabled={org.bucketCounterIsUsed}>
                    <Label text="Bucket Number" />
                  </Item>
                  <Item dataField="clearenceCounterPrefix" caption="Clearence Prefix">
                    <Label text="Clearence Prefix" />
                  </Item>
                  <Item dataField="clearenceCounterNumber" caption="Clearence Number" disabled={org.clearenceCounterIsUsed}>
                    <Label text="Clearence Number" />
                  </Item>
                </GroupItem>
                <ButtonItem
                  horizontalAlignment="left"
                  verticalAlignment="bottom"
                  buttonOptions={buttonOptions}
                />
              </Form>
            </div>
          </TabItem>
          <TabItem title="VATs" icon="percent" disabled={org.id === undefined ? true : false}>
            <div className={'dx-card-custom responsive-paddings-custom'}>
              <VATsGrid />
            </div>
          </TabItem>
          <TabItem title="Nominal Codes" icon="money" disabled={org.id === undefined ? true : false}>
            <div className={'dx-card-custom responsive-paddings-custom'}>
              <NominalCodesGrid />
            </div>
          </TabItem>
        </TabPanel>
      </div>
    </div>
  )
})

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
}