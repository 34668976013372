import { TransactionId } from "../bucket"
import { ClearenceType, IClearence } from "./clearences"

export class PortClearence implements IClearence {
    declaration: string = "";
    id: string = '';
    transactionId?: TransactionId = undefined;
    type: ClearenceType = ClearenceType.Port;
    route: string = "IE-GB";
    requestedBy?: string = undefined
    requested?: string = undefined
    exit: PortClearenceAuthority | undefined = undefined;
    entry: PortClearenceAuthority | undefined = undefined;
    status?: number = 1
    teams?: string[] | undefined = undefined
}

export class PortClearenceFromValues {
    id: string = '';
    declaration: string = "";
    transactionId?: TransactionId = undefined;
    type: ClearenceType = ClearenceType.Port;
    route: string = "IE-GB";
    requestedBy?: string = undefined
    requested?: string = undefined
    exit: PortClearenceAuthority | undefined = undefined;
    entry: PortClearenceAuthority | undefined = undefined;
    status?: number = 1
    teams?: string[] | undefined = undefined

    constructor(portClearence?: PortClearence) {
        if (portClearence) {
            this.id = portClearence.id
            this.declaration = portClearence.declaration
            this.transactionId = portClearence.transactionId
            this.type = portClearence.type
            this.route = portClearence.route
            this.requestedBy = portClearence.requestedBy
            this.requested = portClearence.requested
            this.exit = portClearence.exit
            this.entry = portClearence.entry
            this.status = portClearence.status
            this.teams = portClearence.teams
        }
    }
}

export enum ClearenceAuthorityType {
    PBN = 1,
    GMR = 2,
}

export class PortClearenceAuthority {
    type: ClearenceAuthorityType = ClearenceAuthorityType.PBN;
    authorityNumber: string = "";
    direction: string = "";
    vehicle?: PortClearenceVehicle = undefined;
    contact?: PortClearenceContact = undefined;
    mrNs: PortClearenceMrn[] = [];

    constructor(type: ClearenceAuthorityType) {
        this.type = type
    }
}

interface PortClearenceTask { }

export class PortClearenceVehicle implements PortClearenceTask {
    isUnaccompanied: boolean = false;
    emptyVehicle: boolean = false;
    vehicleRegistration: string = "";
    trailerRegistration: string = "";
    containerRegistration: string = "";
}

export class PortClearenceContact implements PortClearenceTask {
    name: string = "";
    mobileNumber1: string = "";
    mobileNumber2: string = "";
    email: string = "";
}

export class PortClearenceMrn {
    number: string = "";
}
