import agent from "../api/agent"
import { makeAutoObservable, runInAction } from "mobx"
import { Address, AddressResponse } from "../models/address"

export default class AddressStore {
    addressList: Address[] | undefined = undefined
    address: Address | undefined = undefined
    loading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingAddress = (state: boolean) => {
        this.loading = state
    }

    getAddresses = async () => {
        try {
            this.setLoadingAddress(true)

            const response = await agent.Address.getAll()

            if (response.data) {
                const tempArray: Address[] = []
                response.data.map((data: AddressResponse) => (
                    tempArray.push(this.createAddressObj(data))
                ))
                runInAction(() => {
                    this.addressList = tempArray
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingAddress(false)
            })
        }
    }

    getAddress = async (id: string) => {
        try {
            this.setLoadingAddress(true)

            const response = await agent.Address.get(id)

            if (response.data)
                return this.createAddressObj(response.data)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingAddress(false)
            })
        }
    }

    updateAddress = async (organisationId: string, address: Address) => {
        try {
            this.setLoadingAddress(true)

            console.log("updateAddress", JSON.stringify(address))

            const response = await agent.Address.update(organisationId, address)
            console.log(response)
            if (response.data) {

                const tempArray = this.addressList?.map(a => a.id === address.id ? address : a)

                runInAction(() => {
                    this.addressList = tempArray
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingAddress(false)
            })
        }
    }

    createAddress = async (organisationId: string, address: Address) => {
        try {
            this.setLoadingAddress(true)
            const response = await agent.Address.create(organisationId, address)
            console.log(response)
            if (response.data) {
                address.id = response.data.id
                runInAction(() => {
                    this.addressList?.push(address)
                })
            }
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingAddress(false)
            })
        }
    }

    deleteAddress = async (organisationId: string, id: string) => {
        try {
            this.setLoadingAddress(true)

            await agent.Address.delete(organisationId, id)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingAddress(false)
            })
        }
    }

    private createAddressObj(data: AddressResponse): Address {
        let address: Address = {
            organisationId: data.organisation?.id,
            id: data.id,
            code: data.code,
            name: data.name,
            eori: data.eori,
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            region: data.region,
            postcode: data.postcode,
            countryISOCode: data.country?.isoCode,
            status: data.status,
            notes: data.notes,
            lat: data.lat,
            lon: data.lon,
            lastUsed: data.lastUsed,
            type: data.type,
            tags: data.tags,
            areas: data.areas,
            personId: data.person?.id
        }

        return address
    }
}