import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ContentPage from '../layout/contentPage/ContentPage'
import NotFound from '../../features/errors/notFound/NotFound'
import HomePage from '../../features/home/homePage/HomePage'
import ServerError from '../../features/errors/serverError/ServerError'
import RegistrationThankYou from '../layout/registrationThankYou/RegistrationThankYou'

export default function UnauthenticatedContent() {

    return (
        <Switch>
            <Route path='/server-error' >
                <ContentPage title="Server Error">
                    <ServerError />
                </ContentPage>
            </Route>
            <Route exact path='/' >
                <ContentPage>
                    <HomePage />
                </ContentPage>
            </Route>
            <Route exact path='/registration-thank-you' >
                <ContentPage>
                    <RegistrationThankYou />
                </ContentPage>
            </Route>
            <Route >
                <ContentPage>
                    <NotFound />
                </ContentPage>
            </Route>
        </Switch>
    )
}
