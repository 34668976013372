import React from 'react'
import ScrollView from 'devextreme-react/scroll-view'
import './singleCard.css'

interface Props {
    title: string,
    description?: string,
    children: any,
    className?: string
}

export default function SingleCard({ title, description, children, className }: Props) {
    return (
        <ScrollView height={'100%'} width={'100%'} className={(className) ? className : 'with-footer single-card'}>
            <div className={'dx-card content'} style={{width: 420, height: 630}}>
                <div className={'header'}>
                    <div className={'title'}>{title}</div>
                    <div className={'description'}>{description}</div>
                </div>
                {children}
            </div>
        </ScrollView>
    )
}
