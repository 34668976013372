import agent from "../api/agent"
import { makeAutoObservable, runInAction } from "mobx"
import { PortClearence } from "../models/clearences/portClearence"

export const PortClearenceRoutes = [
    { value: 1, description: 'IE-GB' },
    { value: 2, description: 'GB-IE' },
]

export default class PortClearenceStore {
    loading: boolean = false
    portClearence?: PortClearence

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingPortClearence = (state: boolean) => {
        this.loading = state
    }

    setPortClearence(portClearence?: PortClearence) {
        this.portClearence = portClearence
    }

    getPortClearence = async (bucketId: string, clearanceId: string) => {
        try {
            this.setLoadingPortClearence(true)

            const response = await agent.PortClearence.get(bucketId, clearanceId)

            if (response.data)
                return response.data

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingPortClearence(false)
            })
        }
    }

    deletePortClearence = async (bucketId: string, clearanceId: string) => {
        try {
            this.setLoadingPortClearence(true)

            // TODO: If clearence not found, success is false but HTTP response is 200 (OK). Is that right?
            let response = await agent.PortClearence.delete(bucketId, clearanceId)

            return response.data

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingPortClearence(false)
            })
        }
    }

    updatePortClearence = async (bucketId: string, port: PortClearence) => {
        try {
            this.setLoadingPortClearence(true)

            const response = await agent.PortClearence.update(bucketId, port.id, port)

            return response.data
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingPortClearence(false)
            })
        }
    }

    createPortClearence = async (bucketId: string, port: PortClearence) => {
        try {
            this.setLoadingPortClearence(true)

            const response = await agent.PortClearence.create(bucketId, port)

            return response.data
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingPortClearence(false)
            })
        }
    }

    // private createPortObj(data: BucketResponse) : Bucket {
    //     let bucket: Bucket = {
    //         id: data.id,
    //         transactionId: data.transactionId,
    //         requestedBy: data.requestedBy,
    //         requestedDate: data.requestedDate,
    //         customerId: data.customer?.id,
    //         customerName: data.customerName,
    //         billTo: data.billTo,
    //         billToName: data.billToName,
    //         status: data.status,
    //         clearances: data.clearances,
    //         created: data.created,
    //         createdBy: data.createdBy,
    //         lastModified: data.lastModified,
    //         lastModifiedBy: data.lastModifiedBy,
    //         tags: data.tags,
    //         teams: data.teams,            
    //     }

    //     return bucket;
    // }

}