import React from 'react'
import ScrollView from 'devextreme-react/scroll-view'
import './contentPage.css'

interface Props {
    title?: string,
    description?: string,
    children: any,
    className?: string
}

export default function ContentPage({ title, description, children, className }: Props) {
    return (
        <ScrollView height={'100%'} width={'100%'} className={(className) ? className : 'content-page'}>
            <div className={'dx-card content'}>
                {(title || description) && (<div className={'header'}>
                    {title && (<div className={'title'}>{title}</div>)}
                    {description && (<div className={'description'}>{description}</div>)}
                </div>)}
                {children}
            </div>
        </ScrollView>
    )
}
