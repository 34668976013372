export interface Pagination {
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
    totalPages: number
}

export class PaginatedResult<T> {
    data: T
    pagination: Pagination

    constructor(data: T, pagination: Pagination) {
        this.data = data
        this.pagination = pagination
    }
}

export class PagingParams {
    pageNumber
    pageSize

    constructor(pageNumber = 1, pageSize = 2) {
        this.pageNumber = pageNumber
        this.pageSize = pageSize
    }
}

export class ResultData<T> {
    data: T

    constructor(response: Response<T>) {
        this.data = response.data
    }
}


export interface Response<T> {
    succeeded: boolean,
    message: string,
    errors: string[],
    data: T
}