import React from 'react'
import Form, { GroupItem, Item, Label } from 'devextreme-react/form'
import { PortClearence } from '../../../../app/models/clearences/portClearence'
import MRNsDetails from '../shared/MRNsDetails'

interface Props {
    isEntry: Boolean
    portClearence: PortClearence
}

export default function GMRDetails({ portClearence, isEntry }: Props) {

    const colCountByScreen = {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2
    }

    return (
        <Form formData={portClearence} colCountByScreen={colCountByScreen} >
            <GroupItem caption={isEntry ? "GMR - Entry" : "GMR - Exit"} >
                <Item dataField={(isEntry ? "entry" : "exit") + ".vehicle.isUnaccompanied"}
                    editorType="dxCheckBox">
                    <Label text="Unaccompanied" />
                </Item>
                <Item dataField={(isEntry ? "entry" : "exit") + ".vehicle.vehicleRegistration"}>
                    <Label text="Vehicle reg" />
                </Item>
                <Item dataField={(isEntry ? "entry" : "exit") + ".vehicle.trailerRegistration"}>
                    <Label text="Trailer reg" />
                </Item>
                <Item dataField={(isEntry ? "entry" : "exit") + ".vehicle.containerRegistration"}>
                    <Label text="Container reg" />
                </Item>
            </GroupItem>
            <GroupItem caption={isEntry ? "MRNs - Entry" : "MRNs - Exit"}>
                <MRNsDetails isEntry={isEntry} />
            </GroupItem>
        </Form>
    )
}