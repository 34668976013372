import { AppState, Auth0Provider } from "@auth0/auth0-react"
import React, { PropsWithChildren } from "react"
import { useHistory } from "react-router-dom"


export const AuthProvider = ({ children }: PropsWithChildren<any>): JSX.Element | null => {
    const history = useHistory()

    const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN
    const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID
    const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE

    const onRedirectCallback = (appState: AppState) => {
        history.push(appState?.returnTo || window.location.pathname)
    }

    if (!(domain && clientId && audience)) {
        return null
    }


    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            audience={audience}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
        >
            {children}
        </Auth0Provider>
    )
}
