import { DataGrid, LoadPanel } from "devextreme-react"
import { Column, Editing, Paging } from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import ActionArea from "../../../app/layout/actionArea/ActionArea"
import { ActionButton } from "../../../app/models/actionButton"
import { useHistory } from "react-router-dom"
import { useStore } from "../../../app/stores/store"
import { EditingStartEvent, } from "devextreme/ui/data_grid"
import { Address } from "../../../app/models/address"


const AddressPage: React.FC = () => {
    const history = useHistory()
    const { addressStore: { loading, addressList, getAddresses, deleteAddress } } = useStore()

    useEffect(() => {
        getAddresses()
    }, [getAddresses])

    if (loading) {
        return <LoadPanel visible={true} />
    }

    const buttons: ActionButton[] = [
        {
            icon: 'add',
            type: 'success',
            text: '',
            onClick: () => history.push('/create-address')
        }
    ]

    const handleRowRemoved = (row: any) => {
        deleteAddress(row.data.organisationId, row.data.id).then(() => {
            notify(`Address deleted: ${row.data.code} - ${row.data.city}`, 'success')
        })
    }

    const handleEditingStarting = (e: EditingStartEvent<Address, any>) => {
        e.cancel = true
        history.push(`/edit-address/${e.data.id}`)
    }

    return (
        <div className="content-block">
            <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
                <h4 style={{ margin: '15px 5px 15px 30px' }}>Addresses</h4>
                <ActionArea buttons={buttons} />
            </div>

            <div className="content-block">
                <div className={'dx-card'}>
                    <DataGrid
                        className='stratum-grid'
                        dataSource={addressList}
                        keyExpr="id"
                        showBorders={true}
                        onRowRemoved={handleRowRemoved}
                        onEditingStart={e => handleEditingStarting(e)}
                    >

                        <Paging enabled={true} />
                        <Editing
                            allowUpdating={true}
                            allowAdding={false}
                            allowDeleting={true}
                            mode="row" />
                        <Column dataField="code" />
                        <Column dataField="name" />
                        <Column dataField="postcode" />
                        <Column dataField="city" />
                        <Column dataField="organisationId" visible={false} />
                    </DataGrid>
                </div>
            </div>
        </div>
    )

}

export default observer(AddressPage)