import { Address } from "../address"
import { TransactionId } from "../bucket"
import { ClearenceAuthorityType, PortClearence, PortClearenceAuthority } from './portClearence'

export enum ClearenceType {
  None = 0,
  Declaration = 1,
  Port = 2,
}

export interface IClearence {
  id: string
  transactionId?: TransactionId
  type: ClearenceType
  route: string
  requestedBy?: string,
  requested?: string,
  status?: number
  teams?: string[]
}

export class ClearenceFromValues {
  id: string = ''
  transactionId?: TransactionId = undefined
  type: ClearenceType = ClearenceType.Port
  route: string = "IE-GB"
  requestedBy?: string = undefined
  requested?: string = undefined
  status?: number = 1
  teams?: string[] | undefined = undefined
  // Declaration...
  consignor?: IConsignmentPerson = undefined;
  consignee?: IConsignmentPerson = undefined;
  // Port....
  declaration: string = "";
  exit: PortClearenceAuthority | undefined = undefined;
  entry: PortClearenceAuthority | undefined = undefined;


  constructor(clearance?: IClearence | PortClearence | DeclarationClearence) {
    if (clearance) {
      this.id = clearance.id
      this.transactionId = clearance.transactionId
      this.type = clearance.type
      this.route = clearance.route
      this.requestedBy = clearance.requestedBy
      this.requested = clearance.requested
      this.status = clearance.status
      this.teams = clearance.teams
      if (clearance.type === ClearenceType.Port) {
        this.declaration = (clearance as PortClearence).declaration
        this.exit = (clearance as PortClearence).exit ?? new PortClearenceAuthority(ClearenceAuthorityType.PBN)
        this.entry = (clearance as PortClearence).entry ?? new PortClearenceAuthority(ClearenceAuthorityType.GMR)
      } else if (clearance.type === ClearenceType.Declaration) {
        this.consignor = (clearance as DeclarationClearence).consignor
        this.consignee = (clearance as DeclarationClearence).consignee
      }
    }
  }
}

export class DeclarationClearence implements IClearence {
  id: string = "";
  transactionId?: TransactionId = undefined;
  type: ClearenceType = ClearenceType.Declaration;
  route: string = "IE-GB";
  requestedBy?: string = undefined
  requested?: string = undefined
  consignor?: IConsignmentPerson = undefined;
  consignee?: IConsignmentPerson = undefined;
  status?: number = 1
  teams?: string[] | undefined = undefined
}

export interface IConsignmentPerson {
  eori?: string
  name?: string
  address?: Address
  country?: string
}
