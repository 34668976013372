import agent from "../api/agent"
import { makeAutoObservable, runInAction } from "mobx"
import { Supplier, SupplierResponse } from "../models/supplier"

export default class SupplierStore {
    supplierList: Supplier[] | undefined = undefined
    supplier: Supplier | undefined = undefined
    loading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingSupplier = (state: boolean) => {
        this.loading = state
    }

    getSuppliers = async () => {
        try {
            this.setLoadingSupplier(true)

            const response = await agent.Supplier.getAll()

            if (response.data) {
                const tempArray: Supplier[] = []
                response.data.map((data: SupplierResponse) => (
                    tempArray.push(this.createSupplierObj(data))
                ))
                runInAction(() => {
                    this.supplierList = tempArray
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingSupplier(false)
            })
        }
    }

    getSupplier = async (id: string) => {
        try {
            this.setLoadingSupplier(true)
            const response = await agent.Supplier.get(id)

            if (response.data)
                return this.createSupplierObj(response.data)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingSupplier(false)
            })
        }
    }

    updateSupplier = async (organisationId: string, Supplier: Supplier) => {
        try {
            this.setLoadingSupplier(true)
            const response = await agent.Supplier.update(organisationId, Supplier)

            if (response.data) {
                const tempArray = this.supplierList?.map(a => a.id === Supplier.id ? Supplier : a)
                runInAction(() => {
                    this.supplierList = tempArray
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingSupplier(false)
            })
        }
    }

    createSupplier = async (organisationId: string, Supplier: Supplier) => {
        try {
            this.setLoadingSupplier(true)
            const response = await agent.Supplier.create(organisationId, Supplier)

            if (response.data) {
                Supplier.id = response.data.id
                runInAction(() => {
                    this.supplierList?.push(Supplier)
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingSupplier(false)
            })
        }
    }

    deleteSupplier = async (orgId: string, id: string) => {
        try {
            this.setLoadingSupplier(true)

            await agent.Supplier.delete(orgId, id)

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingSupplier(false)
            })
        }
    }

    private createSupplierObj(data: SupplierResponse): Supplier {
        let supplier: Supplier = {
            code: data.code,
            name: data.name,
            eori: data.eori,
            contact: data.contact,
            currencyCode: data.currency?.code,
            tax: data.tax,
            address: data.address,
            billingAddress: data.billingAddress,
            tags: data.tags,
            id: data.id,
            status: data.status,
            operatorType: data.operatorType
        }

        if (supplier.tax?.tax) {
            supplier.tax!.taxId = supplier.tax.tax.id
        }

        if (data.organisation) {
            supplier.organisationId = data.organisation.id
        }

        if (data.address?.country) {
            supplier.address.countryISOCode = data.address.country.isoCode
        }

        if (data.billingAddress.country) {
            supplier.billingAddress.countryISOCode = data.billingAddress.country.isoCode
        }

        return supplier
    }
}