import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores/store"
import './notificationPanel.css'

export default observer(function NotificationPanel() {
    const { notificationStore: { loading, notificationRegistry } } = useStore()

    useEffect(() => {

    }, [loading, notificationRegistry])

    if (!notificationRegistry || notificationRegistry.size <= 0) {
        return null
    }

    return (
        <div>
            {Array.from(notificationRegistry.values()).map((notification) => (
                <div key={notification.id} className={`note note-${notification.type}`}>
                    <div className={`note-header-${notification.type}`}>{notification.header}</div>
                    <div className={`note-text-${notification.type}`}>{notification.text}</div>
                    {(notification.link && notification.link !== '') && <div className={`note-text-${notification.type}`}>Press <a style={{ color: 'white' }} href={notification.link}>Link</a></div>}
                </div>
            ))}

        </div>
    )
})