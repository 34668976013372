import DataSource from 'devextreme/data/data_source';
import { Team } from '../models/account';
import { useStore } from '../stores/store'

export default function useTeamTags(teamIds: string[], onTeamsChanged: (teamsIds: string[]) => void ) {
    const { accountStore: { teams } } = useStore()    
    const allTeams: Team[] = teams || []
    const tagBoxData = new DataSource(teams?.map(({name}) => name) ?? []);  

    const initTagValues: string[] = []
    
    if (teamIds.length > 0) {
        teamIds.forEach(teamId => {
            let team = teams?.find(team => team.id === teamId)
            if (team)
                initTagValues.push(team.name)
        })
    }
    
    const tagBoxValueChanged = (teamNames: string[]) => {
        console.log('useTeamTags.tagBoxValueChanged', teamNames)
        let teamIds = getSelectedTeamsIds(teamNames)
        onTeamsChanged(teamIds)
    }

    const getSelectedTeamsIds = (selectedTeams: string[]): string[] => {
        let teamIds: string[] = []

        selectedTeams.forEach(teamName => {
            let team = allTeams.find(team => team.name === teamName)
            if (team) {
                teamIds.push(team.id)
            }
        })

        return teamIds
    }

    return { tagBoxData, initTagValues, tagBoxValueChanged }
}