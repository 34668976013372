import React from 'react'
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Form,
  Lookup,
} from 'devextreme-react/data-grid'
import { Item, Label } from 'devextreme-react/form'
import { useStore } from '../../../app/stores/store'
import notify from 'devextreme/ui/notify'
import { countryOptions } from '../../../app/common/options/countryOptions'
import { MetadataStatus } from '../../../app/models/metadata'
import { v4 as uuid } from "uuid"
import { observer } from 'mobx-react-lite'

const VATsGrid = () => {
  const { organisationStore } = useStore()
  const { organisation, deleteVat, updateVat, createVat, removeVatFromList } = organisationStore

  const handleRowRemoved = (row: any) => {
    deleteVat(organisation!.id!, row.data.id).then(() => {
      notify(`Vat deleted: ${row.data.code} - ${row.data.description}`, 'success')
    })
  }

  const handleRowInserting = (row: any) => {
    const isCanceled = new Promise((resolve, reject) => {
      createVat(organisation!.id!, row.data).then(() => {
        return resolve(false)
      }).catch((error) => {
        reject(error)
      })
    })
    row.cancel = isCanceled
  }

  const handleRowInserted = (row: any) => {
    notify(`Vat Created: ${row.data.code} - ${row.data.description}`, 'success')
    // remove the temp vat created before the DB save....
    removeVatFromList(row.data.id)
  }

  const handleRowUpdated = (row: any) => {
    updateVat(organisation!.id!, row.data).then(() => {
      notify(`Vat Updated: ${row.data.code} - ${row.data.description}`, 'success')
    })
  }

  const handleInitNewRow = (e: any) => {
    const defaultValues = {
      id: uuid(),
      type: null,
      displayOrder: 0,
      isDeleted: false,
      symbol: null,
      default: false,
      code: '',
      description: '',
      value: '',
      status: 1,
      countryCode: organisation?.address.countryISOCode ?? 'IE'
    }
    e.data = defaultValues
  }

  return (
    <DataGrid
      className='stratum-grid'
      dataSource={organisation?.listSettings?.vatRates ?? []}
      keyExpr="id"
      showBorders={true}
      onRowRemoved={handleRowRemoved}
      onRowInserting={handleRowInserting}
      onRowInserted={handleRowInserted}
      onRowUpdated={handleRowUpdated}
      onInitNewRow={handleInitNewRow}
    >
      <Paging enabled={false} />
      <Editing
        mode="popup"
        refreshMode='reshape'
        allowUpdating={true}
        allowAdding={true}
        allowDeleting={true}
      >
        <Popup title="VAT Info" showTitle={true} width={700} height={525} />
        <Form>
          <Item itemType="group" colCount={2} colSpan={2}>
            <Item dataField="code" isRequired={true} />
            <Item dataField="description" isRequired={true} />
            <Item dataField="value" isRequired={true} />
            <Item dataField="countryCode"
              isRequired={true}
              editorType="dxSelectBox"
              text={"Country"}
              editorOptions={{
                items: countryOptions,
                searchEnabled: true,
                displayExpr: "name",
                valueExpr: "isoCode",
                placeholder: "Select a country..."
              }}>
              <Label text="Country" />
            </Item>
            <Item dataField="status"
              isRequired={true}
              editorType="dxSelectBox"
              text={"Status"}
              editorOptions={{
                items: MetadataStatus,
                searchEnabled: true,
                displayExpr: "description",
                valueExpr: "value",
                placeholder: "Status..."
              }}>
              <Label text="Status" />
            </Item>
            <Item dataField="displayOrder" />
          </Item>
        </Form>
      </Editing>
      <Column dataField="code" />
      <Column dataField="description" />
      <Column dataField="value" />
      <Column dataField="countryCode" />
      <Column dataField="status">
        <Lookup
          dataSource={MetadataStatus}
          valueExpr="value"
          displayExpr="description"
        />
      </Column>
      <Column dataField="displayOrder" caption="Order" />
    </DataGrid>
  )
}

export default observer(VATsGrid)
