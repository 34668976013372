import React, { useCallback } from 'react'
import useTeamTags from '../../hooks/useTeamTags'
import TagBox, { ITagBoxOptions } from 'devextreme-react/tag-box'

interface ITeamsTagBoxProps {
    teamIds?: string[],
    onTeamsChanged: (teamsIds: string[]) => void    
}

const TeamsTagBox: React.FC<ITeamsTagBoxProps & ITagBoxOptions> = (props) => {
  const { teamIds, onTeamsChanged } = props
  const { tagBoxData, initTagValues, tagBoxValueChanged } = useTeamTags(teamIds ?? [], onTeamsChanged)
  
  const onValueChanged = useCallback((e: any) => {
    tagBoxValueChanged(e.value)
  }, [tagBoxValueChanged])
  

  return (
    <TagBox        
        dataSource={tagBoxData}        
        onValueChanged={onValueChanged}
        defaultValue={initTagValues}
        acceptCustomValue={false}                    
        hideSelectedItems={true}
         // other props for TagBox
        {...props}
    />
  )
}

export default TeamsTagBox