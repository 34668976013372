import React from 'react'
import { IClearence } from '../../../../app/models/clearences/clearences'
import { ClearenceAuthorityType, PortClearence } from '../../../../app/models/clearences/portClearence'

interface Props {
    clearence: IClearence
    isEntry: Boolean
}

export default function PortAuthorityView({ clearence, isEntry }: Props) {
    const portClearenceAuthority = isEntry ? (clearence as PortClearence).entry : (clearence as PortClearence).exit

    if (!portClearenceAuthority) return <div>No data</div>

    return (
        <div>
            <p><b>{isEntry ? 'Entry' : 'Exit'} - {ClearenceAuthorityType[portClearenceAuthority.type]}</b></p>

            {portClearenceAuthority.contact && (
                <>
                    <p>Name: {portClearenceAuthority.contact?.name}</p>
                    <p>Mobile number 1: {portClearenceAuthority.contact?.mobileNumber1}</p>
                    <p>Mobile number 2: {portClearenceAuthority.contact?.mobileNumber2}</p>
                    <p>Email: {portClearenceAuthority.contact?.email}</p>
                </>
            )}

            {portClearenceAuthority.vehicle && (
                <>
                    <p>Is Unaccompanied: {portClearenceAuthority.vehicle?.isUnaccompanied ? "Yes" : "No"}</p>
                    <p>Vehicle Reg: {portClearenceAuthority.vehicle?.vehicleRegistration}</p>
                    <p>Trailer Reg: {portClearenceAuthority.vehicle?.trailerRegistration}</p>
                    <p>Container Reg: {portClearenceAuthority.vehicle?.containerRegistration}</p>
                </>
            )}

            <p><b>{isEntry ? 'Entry MRNs' : 'Exit MRNs'}</b></p>

            {portClearenceAuthority.mrNs.map((mrn, index) => {
                return <p key={index}>{mrn.number}</p>
            })}
        </div>
    )
}