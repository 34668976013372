import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { Organisation, OrganisationFormValues } from "../models/organisation"
import { VATMpet } from "../models/vat"
import { store } from './store'
import { NominalCodeMpet } from "../models/nominalCode"
import { Tag } from "../models/tag"

export default class OrganisationStore {
    organisation: Organisation | undefined = undefined
    editMode = false
    loadingOrganisation = false

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingOrganisation = (state: boolean) => {
        this.loadingOrganisation = state
    }

    addTag(tag: Tag) {
        if (!this.organisation)
            return

        if (!this.organisation.tags)
            this.organisation.tags = []

        if (this.organisation.tags.findIndex(x => x.description === tag.description) === -1)
            this.organisation.tags.push(tag)
    }

    getOrganisation = async (id: string) => {
        try {
            this.setLoadingOrganisation(true)

            const response = await agent.Organisation.get(id)

            if (response.data) {
                const org: Organisation = this.setOrganisation(response.data)

                runInAction(() => {
                    this.organisation = org
                })
            }
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingOrganisation(false)
            })
        }
    }

    private setOrganisation = (data: any) => {
        const org: Organisation = {
            id: data.id,
            accountId: data.accountId,
            logo: data.logo,
            address: {
                code: data.address?.code ?? "",
                address1: data.address?.address1,
                address2: data.address?.address2,
                city: data.address?.city,
                postcode: data.address?.postcode,
                countryISOCode: data.address?.country?.isoCode
            },
            contact: data.contact,
            name: data.name,
            status: data.status,
            defaultCurrency: data.defaultCurrency,
            operationalTimeZone: data.operationalTimeZone,
            listSettings: data.listSettings,
            settings: data.settings,
            tags: data.tags ?? [],
        }

        if (org.settings) {
            org.taxIdentifierLabel1 = org.settings.taxIdentifierLabel1
            org.taxIdentifierLabel2 = org.settings.taxIdentifierLabel2

            if (org.settings.transactions?.length === 0) {
                org.bucketCounterPrefix = "BUC"
                org.bucketCounterNumber = 10
                org.bucketCounterIsUsed = false
                org.clearenceCounterPrefix = "CLR"
                org.clearenceCounterNumber = 10
                org.clearenceCounterIsUsed = false
            } else {
                org.settings.transactions?.forEach(counter => {
                    if (counter.type === 1) {
                        org.bucketCounterPrefix = counter.prefix
                        org.bucketCounterNumber = counter.currentValue
                        org.bucketCounterIsUsed = counter.isUsed
                    }
                    if (counter.type === 2) {
                        org.clearenceCounterPrefix = counter.prefix
                        org.clearenceCounterNumber = counter.currentValue
                        org.clearenceCounterIsUsed = counter.isUsed
                    }
                })
            }
        }

        return org
    }

    updateOrganisation = async (organisation: OrganisationFormValues) => {
        try {
            this.setLoadingOrganisation(true)

            let transformedOrg: any = {
                ...organisation,
                defaultCurrency: organisation.defaultCurrency.code,
            }

            const response = await agent.Organisation.update(transformedOrg)

            if (response.data) {

                runInAction(() => {
                    this.organisation = { ...this.organisation, ...transformedOrg }
                })
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.setLoadingOrganisation(false)
            })
        }
    }

    createOrganisation = async (organisation: OrganisationFormValues) => {
        try {

            let transformedOrg: any = {
                ...organisation,
                defaultCurrency: organisation.defaultCurrency.code,
            }

            const org = await agent.Organisation.create(transformedOrg)
            runInAction(() => {
                if (org.data) {
                    store.userStore.setOrganisation(org.data.id!, org.data.name)
                    this.organisation = { ...organisation, ...org.data }
                }
            })
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    getVats = async (id: string) => {
        try {

            const response = await agent.Organisation.getVats(id)

            if (response.data) {
                //  const vat: VATMpet = {
                //      id,
                //      logo: response.data.logo,           
                //      defaultCurrency: response.data.defaultCurrency,
                //      operationalTimeZone: response.data.operationalTimeZone
                //  }
            }

        } catch (error) {
            console.log(error)
            throw error
        }
    }

    deleteVat = async (organisationId: string, vatId: string) => {
        try {

            const response = await agent.Organisation.deleteVat(organisationId, vatId)

            if (response.data) {
                runInAction(() => {
                    // Removing the deleted vat rate from the listlist
                    this.organisation!.listSettings.vatRates! = this.organisation!
                        .listSettings.vatRates!.filter(elem => vatId !== elem.id)
                })
            }


        } catch (error) {
            console.log(error)
            throw error
        }
    }

    updateVat = async (organisationId: string, vat: VATMpet) => {
        try {
            const response = await agent.Organisation.updateVat(organisationId, vat.id, vat)

            runInAction(() => {
                if (response.data) {
                    // Adding new vat created to the in memo vat rate list
                    this.organisation!.listSettings.vatRates! = this.organisation!
                        .listSettings.vatRates!.map(elem => vat.id === elem.id ? vat : elem)
                }
            })

        } catch (error) {
            console.log(error)
            throw error
        }
    }

    createVat = async (organisationId: string, vat: VATMpet) => {
        try {
            const response = await agent.Organisation.createVat(organisationId, vat)
            runInAction(() => {
                if (response.data) {

                    if (!this.organisation?.listSettings) {
                        this.organisation!.listSettings = { vatRates: [], }
                    }

                    if (!this.organisation?.listSettings.vatRates) {
                        this.organisation!.listSettings.vatRates = [] as VATMpet[] //new Array<VATMpet>();
                    }
                    // Adding new vat created to the in memo vat rate list
                    this.organisation?.listSettings.vatRates?.push(response.data)
                }
            })

        } catch (error) {
            console.log(error)
            throw error
        }
    }


    removeVatFromList = (id: string) => {
        try {

            this.organisation!.listSettings.vatRates! = this.organisation!
                .listSettings.vatRates!.filter(elem => id !== elem.id)

        } catch (error) {
            console.log(error)
            throw error
        }
    }

    getNominals = async (id: string) => {
        try {

            const response = await agent.Organisation.getNominals(id)

            if (response.data) {
                //  const vat: VATMpet = {
                //      id,
                //      logo: response.data.logo,           
                //      defaultCurrency: response.data.defaultCurrency,
                //      operationalTimeZone: response.data.operationalTimeZone
                //  }
            }

        } catch (error) {
            console.log(error)
            throw error
        }
    }

    deleteNominal = async (organisationId: string, nominalId: string) => {
        try {
            const response = await agent.Organisation.deleteNominal(organisationId, nominalId)

            if (response.data) {
                runInAction(() => {
                    // Removing the deleted nominal rate from the listlist
                    this.organisation!.listSettings.nominalCodes! = this.organisation!
                        .listSettings.nominalCodes!.filter(elem => nominalId !== elem.id)
                })
            }


        } catch (error) {
            console.log(error)
            throw error
        }
    }

    updateNominal = async (organisationId: string, nominal: NominalCodeMpet) => {
        try {

            const response = await agent.Organisation.updateNominal(organisationId, nominal.id, nominal)

            runInAction(() => {
                if (response.data) {

                    // Adding new nominal created to the in memo nominal rate list
                    this.organisation!.listSettings.nominalCodes! = this.organisation!
                        .listSettings.nominalCodes!.map(elem => nominal.id === elem.id ? nominal : elem)
                }
            })

        } catch (error) {
            console.log(error)
            throw error
        }
    }

    createNominal = async (organisationId: string, nominal: NominalCodeMpet) => {
        try {

            const response = await agent.Organisation.createNominal(organisationId, nominal)
            runInAction(() => {
                if (response.data) {

                    if (!this.organisation?.listSettings) {
                        this.organisation!.listSettings = { nominalCodes: [], }
                    }

                    if (!this.organisation?.listSettings.nominalCodes) {
                        this.organisation!.listSettings.nominalCodes = [] as NominalCodeMpet[]
                    }
                    // Adding new nominal created to the in memo nominal rate list
                    this.organisation?.listSettings.nominalCodes?.push(response.data)
                }
            })

        } catch (error) {
            console.log(error)
            throw error
        }
    }

    removeNominalFromList = (id: string) => {
        try {

            this.organisation!.listSettings.nominalCodes! = this.organisation!
                .listSettings.nominalCodes!.filter(elem => id !== elem.id)

        } catch (error) {
            console.log(error)
            throw error
        }
    }
}