import React from 'react'
import { Popup as PopupDx } from 'devextreme-react/popup'

type Props = {
  title: string,
  children: any,
  width: number,
  height: number,
  visible: boolean,
  onClosePopup: Function
}

const Popup = ({ title, visible, children, width, height, onClosePopup }: Props) => {

  return (
    <PopupDx
      visible={visible}
      onHiding={() => onClosePopup()}
      dragEnabled={true}
      closeOnOutsideClick={true}
      showCloseButton={true}
      showTitle={true}
      title={title}
      container=".dx-viewport"
      width={width}
      height={height}
    >
      {children}
    </PopupDx>)
}

export default Popup
