
export const MetadataStatus = [
    { value: -1, description: 'Inactive' },
	{ value: 1, description: 'Active' },
]

export interface Metadata {
    id: string,
    code: string,
    description: string,
    value: string,
    status: number,
    displayOrder: number
}