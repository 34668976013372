import { TagBox } from 'devextreme-react'
import { Tag, TagCategory, TagType } from '../../models/tag'
import useTags from '../../hooks/useTags'
import React, { useCallback } from 'react'
import { ITagBoxOptions } from 'devextreme-react/tag-box'

interface ICustomTagBoxProps {
    tags: Tag[],
    onTagsChanged: (tags: Tag[]) => void,
    category: TagCategory,
    type: TagType
}

const CustomTagBox: React.FC<ICustomTagBoxProps & ITagBoxOptions> = (props) => {
    const { tags, category, type, onTagsChanged } = props
    const { tagBoxData, tagBoxValueChanged, customTagCreated } = useTags(category, type, onTagsChanged)

    const initTagValues: string[] = []
    if (tags.length > 0) {
        tags.forEach(val => initTagValues.push(val.description))
    }

    function onCustomItemCreating(e: any) {
        const newValue = e.text
        e.customItem = newValue
        customTagCreated(newValue)
    }

    const onValueChanged = useCallback((e: any) => {
        tagBoxValueChanged(e.value)
    }, [tagBoxValueChanged])

    return (
        <TagBox
            dataSource={tagBoxData}
            onCustomItemCreating={onCustomItemCreating}
            onValueChanged={onValueChanged}
            defaultValue={initTagValues}
            searchEnabled={true}
            // other props for TagBox
            {...props}
        />
    )
}

export default CustomTagBox