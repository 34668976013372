import { Button, TextBox } from 'devextreme-react'
import Box, { Item } from 'devextreme-react/box'
import notify from 'devextreme/ui/notify'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useStore } from '../../../../app/stores/store'

interface Props {
    isEntry: Boolean

}

export default observer(function MRNsDetails({ isEntry }: Props) {
    const { bucketStore } = useStore()
    const [newMRN, setNewMRN] = useState<string>('')

    function handleMRNTextBoxChanged(e: any) {
        setNewMRN(e.value)
    }

    function handleAddMRN(e: any) {
        if (!newMRN) return

        if (isEntry && bucketStore.entryMrns.findIndex(m => m.number === newMRN) > -1) {
            notify(`MRN ${newMRN} already exists on list`, 'error')
            return
        }
        if (!isEntry && bucketStore.exitMrns.findIndex(m => m.number === newMRN) > -1) {
            notify(`MRN ${newMRN} already exists on list`, 'error')
            return
        }

        bucketStore.addMrn(newMRN, isEntry)

        setNewMRN('')
    }

    function handleRemoveMRN(mrn: string) {
        bucketStore.removeMrn(mrn, isEntry)
    }


    return (
        <div>
            <div style={{ display: 'flex' }}>
                <TextBox value={newMRN} onValueChanged={handleMRNTextBoxChanged} />
                <Button icon='add' type='success' onClick={handleAddMRN} />
            </div>
            <div>


                {isEntry
                    ? bucketStore.entryMrns?.map((mrn, index) => {
                        return (
                            <Box key={index}
                                className='mrn-clearance-box'
                                direction="row"
                                width="100%">
                                <Item ratio={0} baseSize={200}>
                                    <div className='mrn-clearance-number'>
                                        <b>#: </b>{mrn.number}
                                    </div>
                                </Item>
                                <Item
                                    ratio={0}
                                    baseSize="150">
                                    <div className="">
                                        <Button icon='trash' type='danger' onClick={() => handleRemoveMRN(mrn.number)} />
                                    </div>
                                </Item>
                            </Box>
                        )
                    })
                    : bucketStore.exitMrns?.map((mrn, index) => {
                        return (
                            <Box key={index}
                                className='mrn-clearance-box'
                                direction="row"
                                width="100%">
                                <Item ratio={0} baseSize={200} >
                                    <div className='mrn-clearance-number'>
                                        <b>#: </b>{mrn.number}
                                    </div>
                                </Item>
                                <Item
                                    ratio={0}
                                    baseSize="150">
                                    <div className="">
                                        <Button icon='trash' type='danger' onClick={() => handleRemoveMRN(mrn.number)} />
                                    </div>
                                </Item>
                            </Box>
                        )
                    })
                }
            </div>
        </div>
    )
})