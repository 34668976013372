import { DataGrid, LoadPanel } from 'devextreme-react'
import { Column, Editing, Paging } from 'devextreme-react/data-grid'
import React, { useEffect } from 'react'
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"
import { useStore } from "../../../app/stores/store"
import ActionArea from '../../../app/layout/actionArea/ActionArea'
import { ActionButton } from '../../../app/models/actionButton'
import notify from 'devextreme/ui/notify'
import { EditingStartEvent } from 'devextreme/ui/data_grid'
import { Customer } from '../../../app/models/customer'

const CustomerPage: React.FC = () => {
  const history = useHistory()
  const { customerStore: { loading, customerList, getCustomers, deleteCustomer } } = useStore()

  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  if (loading) {
    return <LoadPanel visible={true} />
  }

  const buttons: ActionButton[] = [
    {
      icon: 'add',
      type: 'success',
      text: '',
      onClick: () => history.push('/create-customer')
    }
  ]


  const handleRowRemoved = (row: any) => {
    deleteCustomer(row.data.organisationId, row.data.id).then(() => {
      notify(`Customer deleted: ${row.data.id} - ${row.data.name}`, 'success')
    })
  }

  const handleEditingStarting = (e: EditingStartEvent<Customer, any>) => {
    e.cancel = true
    history.push(`/edit-customer/${e.data.id}`)
  }

  return (
    <div className="content-block">
      <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
        <h4 style={{ margin: '15px 5px 15px 30px' }}>Customers</h4>
        <ActionArea buttons={buttons} />
      </div>

      <div className="content-block">
        <div className={'dx-card'}>
          <DataGrid
            dataSource={customerList}
            keyExpr="id"
            showBorders={true}
            onRowRemoved={handleRowRemoved}
            onEditingStart={e => handleEditingStarting(e)}
          >
            <Paging enabled={true} />
            <Editing
              allowUpdating={true}
              allowAdding={false}
              allowDeleting={true}
              mode="row" />
            <Column dataField="name" />
            <Column dataField="eori" />
            <Column dataField="organisationId" visible={false} />
          </DataGrid>
        </div>
      </div>
    </div>
  )
}

export default observer(CustomerPage)
