import { observer } from "mobx-react-lite"
import React from "react"
import { useStore } from "../../../app/stores/store"
import './serverError.css'

export default observer(function ServerError() {
    const { commonStore } = useStore()

    console.log(`Server Error: ${JSON.stringify(commonStore?.error)}`)

    return (
        <div className='server-error container'>
            <h5 className='server-error message'>{(commonStore?.error?.Message) ? commonStore?.error?.Message : "Unknown error"}</h5>
            {commonStore?.error?.Errors && (
                <div>
                    <h4 className='server-error stack-trace'>Stack trace</h4>
                    <code className='server-error details'>{commonStore.error.Errors}</code>
                </div>
            )}
        </div>
    )
})
